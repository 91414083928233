import * as React from "react";
import { useState, useEffect } from "react";
import SelectReducaoCustos from "./SelectReducaoCustos.js";
import SelectEstrategico from "./SelectEstrategico.js";
import ImpactoEx from "./ImpactoEx.js";
import ImpactoIn from "./ImpactoIn.js";
import EsforcoEx from "./EsforcoEx.js";
import EsforcoIn from "./EsforcoIn.js";
import MandOuReg from "./MandOuReg.js";
import Desempate1 from "./Desempate1.js";
import { toast } from "react-toastify";
import "./PriorityTable.css";
import ConcorremEsforco from "./ConcorremEsforco.js";

export default function PriorityTable(props) {
  const onSave = async (value, project) => {
    props.onSave(value, project);
  };

  const [priorities, setPriorities] = useState([]);
  const [totalsByProjectId, setTotalsByProjectId] = useState({});
  const [sortedPriorities, setSortedPriorities] = useState([]);

  const calculateTotalForProject = (projectID) => {
    const projectPriorities = priorities.filter(
      (value) => value.ID === projectID
    );

    const total = projectPriorities.reduce((acc, curr) => {
      return (
        acc +
        curr.RED_CUSTOS +
        curr.ESTRATEGICO +
        curr.IMPACTO_EXT +
        curr.IMPACTO_INT +
        curr.ESFORCO_EXT +
        curr.ESFORCO_INT +
        curr.MANDAT_REG
      );
    }, 0);

    return total;
  };

  const calculateTotalPosDesempateForProject = (projectID) => {
    const totalForProject = calculateTotalForProject(projectID);
    const desempateValue =
      priorities.find((value) => value.ID === projectID)?.DESEMPATE1 || 0;

    if (desempateValue !== 0) {
      return totalForProject + desempateValue;
    } else {
      return "";
    }
  };

  const getIdsWithDuplicateAndNonZeroTotal = () => {
    const projectTotals = {};
    const duplicateTotals = new Set();
    const duplicateIds = new Set();

    priorities.forEach((value) => {
      const totalForProject = calculateTotalForProject(value.ID);

      if (totalForProject !== 0) {
        if (projectTotals[totalForProject]) {
          duplicateTotals.add(totalForProject);
          duplicateIds.add(value.ID);
          duplicateIds.add(projectTotals[totalForProject]);
        } else {
          projectTotals[totalForProject] = value.ID;
        }
      }
    });

    const idsWithDuplicatesAndNonZeroTotal = Array.from(duplicateIds);

    return idsWithDuplicatesAndNonZeroTotal;
  };

  const getTotalCounts = () => {
    const totalsCount = {};

    priorities.forEach((value) => {
      const totalForProject = calculateTotalForProject(value.ID);

      if (totalForProject !== 0) {
        if (totalsCount[totalForProject]) {
          totalsCount[totalForProject]++;
        } else {
          totalsCount[totalForProject] = 1;
        }
      }
    });

    return totalsCount;
  };

  const totalCounts = getTotalCounts();

  const getPriorities = async (event) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/priorityTable`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (response.ok === false) {
        throw new Error(response.statusText);
      }
      const allPriorities = await response.json();

      setPriorities(allPriorities[0]);
    } catch (error) {
      console.log(error);
      toast.error("Erro ao recarregar projetos.");
    }
  };

  React.useEffect(() => {
    getPriorities();
  }, []);

  useEffect(() => {
    const newTotalsByProjectId = {};
    priorities.forEach((value) => {
      const totalForProject = calculateTotalForProject(value.ID);
      newTotalsByProjectId[value.ID] = totalForProject;
    });
    setTotalsByProjectId(newTotalsByProjectId);
  }, [priorities]);

  useEffect(() => {
    const sortedPriorities = [...priorities];

    // Ordena os projetos com base no total em ordem decrescente
    sortedPriorities.sort((a, b) => {
      const totalForProjectA = calculateTotalForProject(a.ID);
      const totalForProjectB = calculateTotalForProject(b.ID);

      return totalForProjectB - totalForProjectA;
    });

    const newTotalsByProjectId = {};
    sortedPriorities.forEach((value) => {
      const totalForProject = calculateTotalForProject(value.ID);
      newTotalsByProjectId[value.ID] = totalForProject;
    });

    setTotalsByProjectId(newTotalsByProjectId);
    setSortedPriorities(sortedPriorities); // Adicione esse estado
  }, [priorities]);

  return (
    <>
      <div className="tabelaPriority table-wrap">
        <div className="table-projetos">
          <div className="table-header">
            <ul>
              <li className="header-item tab-col-7-1  header-item-wrap">
                <p>PROJETO</p>
              </li>
              <li className=" center header-item tab-col-7-1">
                <p>Redução Custos</p>
              </li>
              <li className=" center header-item tab-col-7-1">
                <p>Estratégico</p>
              </li>
              <li className=" center header-item tab-col-7-1">
                <p>Impacto Cliente Externo</p>
              </li>
              <li className=" center header-item tab-col-7-1">
                <p>Impacto Cliente Interno</p>
              </li>
              <li className=" center header-item tab-col-7-1">
                <p>Esforço Externo</p>
              </li>
              <li className=" center header-item tab-col-7-1">
                <p>Esforço Interno</p>
              </li>
              <li className=" center header-item tab-col-7-1">
                <p>Mandatório ou Regulatório</p>
              </li>
              <li className=" center header-item tab-col-7-1">
                <p className=" ">TOTAL</p>
              </li>
              <li className=" center header-item tab-col-7-1">
                <p>Regra Desempate 1 - Urgência</p>
              </li>
              <li className="  center header-item tab-col-7-1">
                <p className=" ">TOTAL PÓS DESEMPATE</p>
              </li>
              <li className=" center header-item tab-col-7-1">
                <p>Concorrem Esforço</p>
              </li>
            </ul>
          </div>
          {sortedPriorities.map((value) => {
            const totalForProject = calculateTotalForProject(value.ID);
            const totalPosDesempate = calculateTotalPosDesempateForProject(
              value.ID
            );
            const showDesempate1 =
              getIdsWithDuplicateAndNonZeroTotal().includes(value.ID);
            const isSameTotal = totalCounts[totalForProject] > 1;
            return (
              <React.Fragment key={value.ID}>
                <div className="table-line">
                  <ul title={value.NAME}>
                    <li
                      className="table-item tab-col-7-1"
                      style={{ wordWrap: "break-word" }}
                    >
                      <a
                        href={`https://projetos.pmovil.com.br/workgroups/group/${value.ID}/tasks/`}
                        style={{
                          textAlign: "left",
                          wordWrap: "break-word",
                          maxWidth: "100%",
                        }}
                      >
                        {value.NAME}
                      </a>
                    </li>

                    <li className="table-item tab-col-7-1">
                      <SelectReducaoCustos
                        id={value.ID}
                        key={value.ID}
                        value={value.RED_CUSTOS}
                        user={props.user}
                        isAdmin={props.isAdmin}
                        onValueChange={(newValue) =>
                          setPriorities((prevPriorities) =>
                            prevPriorities.map((prevValue) =>
                              prevValue.ID === value.ID
                                ? { ...prevValue, RED_CUSTOS: newValue }
                                : prevValue
                            )
                          )
                        }
                      />
                    </li>

                    <li className="table-item tab-col-7-1">
                      <SelectEstrategico
                        id={value.ID}
                        key={value.ID}
                        value={value.ESTRATEGICO}
                        //key={`statusSelect-${value.ID}`}
                        //value={value.STATUS_COLOR}
                        user={props.user}
                        isAdmin={props.isAdmin}
                        onValueChange={(newValue) =>
                          setPriorities((prevPriorities) =>
                            prevPriorities.map((prevValue) =>
                              prevValue.ID === value.ID
                                ? { ...prevValue, ESTRATEGICO: newValue }
                                : prevValue
                            )
                          )
                        }
                      />
                    </li>
                    <li className="table-item tab-col-7-1">
                      <ImpactoEx
                        id={value.ID}
                        key={value.ID}
                        value={value.IMPACTO_EXT}
                        //key={`statusSelect-${value.ID}`}
                        //value={value.STATUS_COLOR}
                        user={props.user}
                        isAdmin={props.isAdmin}
                        onValueChange={(newValue) =>
                          setPriorities((prevPriorities) =>
                            prevPriorities.map((prevValue) =>
                              prevValue.ID === value.ID
                                ? { ...prevValue, IMPACTO_EXT: newValue }
                                : prevValue
                            )
                          )
                        }
                      />
                    </li>
                    <li className="table-item tab-col-7-1">
                      <ImpactoIn
                        id={value.ID}
                        key={value.ID}
                        value={value.IMPACTO_INT}
                        //key={`statusSelect-${value.ID}`}
                        //value={value.STATUS_COLOR}
                        user={props.user}
                        isAdmin={props.isAdmin}
                        onValueChange={(newValue) =>
                          setPriorities((prevPriorities) =>
                            prevPriorities.map((prevValue) =>
                              prevValue.ID === value.ID
                                ? { ...prevValue, IMPACTO_INT: newValue }
                                : prevValue
                            )
                          )
                        }
                      />
                    </li>
                    <li className="table-item tab-col-7-1">
                      <EsforcoEx
                        id={value.ID}
                        key={value.ID}
                        value={value.ESFORCO_EXT}
                        //key={`statusSelect-${value.ID}`}
                        //value={value.STATUS_COLOR}
                        user={props.user}
                        isAdmin={props.isAdmin}
                        onValueChange={(newValue) =>
                          setPriorities((prevPriorities) =>
                            prevPriorities.map((prevValue) =>
                              prevValue.ID === value.ID
                                ? { ...prevValue, ESFORCO_EXT: newValue }
                                : prevValue
                            )
                          )
                        }
                      />
                    </li>

                    <li className="table-item tab-col-7-1">
                      <EsforcoIn
                        id={value.ID}
                        key={value.ID}
                        value={value.ESFORCO_INT}
                        //key={`statusSelect-${value.ID}`}
                        //value={value.STATUS_COLOR}
                        user={props.user}
                        isAdmin={props.isAdmin}
                        onValueChange={(newValue) =>
                          setPriorities((prevPriorities) =>
                            prevPriorities.map((prevValue) =>
                              prevValue.ID === value.ID
                                ? { ...prevValue, ESFORCO_INT: newValue }
                                : prevValue
                            )
                          )
                        }
                      />
                    </li>
                    <li className="table-item tab-col-7-1">
                      <MandOuReg
                        id={value.ID}
                        key={value.ID}
                        value={value.MANDAT_REG}
                        //key={`statusSelect-${value.ID}`}
                        //value={value.STATUS_COLOR}
                        user={props.user}
                        isAdmin={props.isAdmin}
                        onValueChange={(newValue) =>
                          setPriorities((prevPriorities) =>
                            prevPriorities.map((prevValue) =>
                              prevValue.ID === value.ID
                                ? { ...prevValue, MANDAT_REG: newValue }
                                : prevValue
                            )
                          )
                        }
                      />
                    </li>

                    <li className="center table-item tab-col-7-1">
                      <p
                        className={`select-wrap backgroundcolor ${
                          isSameTotal ? "same-total" : ""
                        }`}
                      >
                        {totalForProject}
                      </p>
                    </li>

                    {showDesempate1 ? (
                      <li className="table-item tab-col-7-1">
                        <Desempate1
                          id={value.ID}
                          key={value.ID}
                          value={value.DESEMPATE1}
                          user={props.user}
                          isAdmin={props.isAdmin}
                          onValueChange={(newValue) =>
                            setPriorities((prevPriorities) =>
                              prevPriorities.map((prevValue) =>
                                prevValue.ID === value.ID
                                  ? { ...prevValue, DESEMPATE1: newValue }
                                  : prevValue
                              )
                            )
                          }
                        />
                      </li>
                    ) : (
                      <li className="table-item tab-col-7-1"></li>
                    )}
                    <li className="center table-item tab-col-7-1">
                      <p className="totalpos select-wrap backgroundcolor">
                        {totalPosDesempate}
                      </p>
                    </li>
                    <li className="center table-item tab-col-7-1">
                      <ConcorremEsforco
                        id={value.ID}
                        key={value.ID}
                        value={value.CONCORREM_ESFORCO}
                        //key={`statusSelect-${value.ID}`}
                        //value={value.STATUS_COLOR}
                        user={props.user}
                        isAdmin={props.isAdmin}
                      />
                    </li>
                  </ul>
                </div>
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </>
  );
}
