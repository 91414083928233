import * as React from 'react';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ExpandMoreIcon className={props.expanded ? 'expandedIcon' : ''} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper': {
    transition: 'transform 0.3s',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  '& .expandedIcon': {
    transform: 'rotate(180deg)',
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function CustomizedAccordions() {
  const [expanded, setExpanded] = React.useState('');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      <div sx={{ position: 'absolute'  }}>
        <h2 sx={{marginBottom: '0px'}} className="legenda">Legenda:</h2>
        <div className="accordionPrincipal">
          <Accordion 
            sx={{ backgroundColor: '#2e75b6', color: 'white', fontWeight: 'bold', borderColor: 'white',fontFamily: 'Poppins,sans-serif'}}
            expanded={expanded === 'panel1'}
            onChange={handleChange('panel1')}
          >
            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
              <Typography sx={{ backgroundColor: '#2e75b6', color: 'white', height:'12px',fontFamily: 'Poppins,sans-serif',  /* textShadow: '0 0 0.2em rgb(255, 255, 255), 0 0 0.2em rgb(115, 153, 228)' */}}>
              <span className= 'bold'>R:</span> Responsável
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: 'white', color: 'black',fontFamily: 'Poppins,sans-serif' }}>
              <Typography sx={{ backgroundColor: 'white', color: 'black',fontFamily: 'Poppins,sans-serif' }}>
                Responsável por realizar a tarefa
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{ backgroundColor: '#bdd7ee', color: 'white', borderColor: 'white' ,fontFamily: 'Poppins,sans-serif'}}
            expanded={expanded === 'panel2'}
            onChange={handleChange('panel2')}
          >
            <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
              <Typography sx={{ backgroundColor: '#bdd7ee', border: 'black' ,color: 'black', height:'12px', textAlign: 'center' ,fontFamily: 'Poppins,sans-serif',  textShadow: '0 0 0.2em rgb(255, 255, 255), 0 0 0.2em rgb(115, 153, 228)'}}>
                <span className= 'bold'>A:</span> Aprovador
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: 'white', color: 'black',fontFamily: 'Poppins,sans-serif' }}>
              <Typography sx={{ backgroundColor: 'white', color: 'black' ,fontFamily: 'Poppins,sans-serif',}}>
                Autoridade para aprovar a tarefa ou etapa
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{ backgroundColor:'#1E90FF', color: 'black' , borderColor: 'white',fontFamily: 'Poppins,sans-serif'}}
            expanded={expanded === 'panel3'}
            onChange={handleChange('panel3')}
          >
            <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
              <Typography sx={{ backgroundColor: '#1E90FF', color: 'white' , height:'12px',fontFamily: 'Poppins,sans-serif',  /* textShadow: '0 0 0.2em rgb(255, 255, 255), 0 0 0.2em rgb(115, 153, 228)' */}}>
              <span className= 'bold'>C:</span> Consultado
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: 'white', color: 'black' ,fontFamily: 'Poppins,sans-serif'}}>
              <Typography sx={{ backgroundColor: 'white', color: 'black' ,fontFamily: 'Poppins,sans-serif'}}>
                Consultado para participar da tarefa
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{ backgroundColor: '#adb9ca', color: 'white', borderColor: 'white' ,fontFamily: 'Poppins,sans-serif'}}
            expanded={expanded === 'panel4'}
            onChange={handleChange('panel4')}
          >
            <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
              <Typography sx={{ backgroundColor: '#adb9ca', color: 'black' , height:'12px', padding: 0,fontFamily: 'Poppins,sans-serif',  textShadow: '0 0 0.2em rgb(255, 255, 255), 0 0 0.2em rgb(115, 153, 228)'}}>
              <span className= 'bold'>I:</span> Informado
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: 'white', color: 'black' ,fontFamily: 'Poppins,sans-serif'}}>
              <Typography sx={{ backgroundColor: 'white', color: 'black' ,fontFamily: 'Poppins,sans-serif'}}>
                Informado a respeito do status da tarefa
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </>
  );
}
