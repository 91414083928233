import http from "../http-common";
import axios from "axios";

const urlUpload = `${process.env.REACT_APP_API_URL}/upload`;

class UploadFile {
  upload(file) {

    const formData = new FormData();
    formData.append("file", file);

    return axios.post(`${urlUpload}/?a=upload`, formData)
      .then((response) => {
        // Lógica de manipulação da resposta do servidor após o upload
        return response;
      })
      .catch((error) => {
        // Lógica de manipulação de erros
        return error;
      });
  };
  removeFile(fileName){
    // Fazer a chamada à API PHP para remover o arquivo
    // Você pode usar o método fetch ou uma biblioteca como axios
    
    // Exemplo de uso do fetch:
    fetch(`${urlUpload}/?a=remove&fileName=${fileName}`, { method: "POST" })
      .then((response) => {
       return response;
      })
      .catch((error) => {
        return error;
      });
  };
  async getFiles() {
    const response = await fetch(
      `${urlUpload}/?a=list`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.ok === false) {
      throw new Error(response.statusText);
    }
    return  await response.json();
  }

  async getUpdateDate() {
    const response =  await fetch(
      `${urlUpload}/?a=getDateUpdate`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    console.log("getUpdateDate Response: ", response)
    if (response.ok === false) {
      throw new Error(response.statusText);
    }
    return  await response.json();
  };
  
  updateDate(data) {

    const response =  fetch(
      `${urlUpload}/?a=setDateUpdate&date=${data}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    console.log("setDateUpdate Response: ", response)

    if (response.ok === false) {
      throw new Error(response.statusText);
    }
    return   response.json();
  };

}

export default new UploadFile();