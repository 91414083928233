import * as React from "react";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { toast } from "react-toastify";
import { green } from "@mui/material/colors";
import { autocompleteClasses, Card, CircularProgress } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";

import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "rgba(217,217,271, .7)",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#d9d9d9 ",
  },
  "& .MuiOutlinedInput-root": {
    backgroundColor: "rgba(217,217,271, .7)",
    borderRadius: 15,

    "& fieldset": {
      borderColor: "black ",
    },
    "&:hover fieldset": {
      borderColor: "black ",
    },
    "&.Mui-focused fieldset": {
      borderColor: "black ",
    },
  },
});
export default function Login(props) {
  const [password, setPassword] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [remember, setRemember] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get("code");
  const sessionState = searchParams.get("session_state");

  console.log(location);
  const navigate = useNavigate();

  const onChangePassword = (event) => {
    setPassword(event.target.value);
  };

  const onChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  const onChangeRemeber = (event) => {
    setRemember(event.target.checked);
    //(event.target.checked);
  };

  const onSubmitLogin = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const data = { email, password, remember };

      const response = await fetch(`${process.env.REACT_APP_API_URL}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (response.ok === false) {
        throw new Error(response.statusText);
      }
      const user = await response.json();
      props.onLogin(user);

      localStorage.setItem("user", JSON.stringify(user));
    } catch (error) {
      toast.error("Erro ao efetuar o login.");
    } finally {
      setLoading(false);
    }
  };

  const getParams = async () => {
    try {
      const response = await fetch(
        `https://api.dash-entre.pmovil.com.br/social/auth/azureadoauth2/callback?code=${code}&session_state=${sessionState}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.ok === false) {
        throw new Error(response.statusText);
      }

      const user = await response.json();
      props.onLogin(user);
      localStorage.setItem("user", JSON.stringify(user));
      console.log(code, "code", sessionState, "session state");
    } catch (error) {
      console.log(error);
      //   toast.error("Erro ao  carregar.");
    }
  };

  useEffect(() => {
    getParams();
  }, []);

  return (
    <>
      <div className="container">
        <Grid container className="box" direction={{ xs: "column", sm: "row" }}>
          <Grid
            container
            item
            xs={12}
            md={6}
            className="grid-1-login"
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <img
              className="my-app-logo"
              src="/images/logo-pmovil.png"
              alt="logo pmovil"
              style={{ width: "36%" }}
            />
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={6}
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="h5" mt={5} style={{ fontWeight: "600" }}>
              Faça o login
            </Typography>
            <Box
              className="fieldStyle"
              component="form"
              noValidate
              onSubmit={onSubmitLogin}
            >
              <Grid container direction="column" justifyContent="center">
                <Grid item xs={12} sx={{ mb: 4, mt: 4 }}>
                  <CssTextField
                    type="email"
                    className="inputLogin"
                    placeholder="email"
                    variant="standard"
                    onChange={onChangeEmail}
                    value={email}
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img
                            src="/images/login-entre-ico.png"
                            alt="logo entre email"
                            style={{ width: "70%", paddingLeft: "30%" }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CssTextField
                    type="password"
                    className="inputLogin"
                    placeholder="senha"
                    variant="standard"
                    onChange={onChangePassword}
                    value={password}
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img
                            src="/images/pass-entre-ico.png"
                            alt="logo entre password"
                            style={{ width: "70%", paddingLeft: "30%" }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={6} style={{ marginTop: "20px" }}>
                  <FormControlLabel
                    sx={{ mr: 0, paddingLeft: "0.5rem" }}
                    control={
                      <Checkbox onChange={onChangeRemeber} defaultChecked />
                    }
                  />
                  <Typography variant="span" sx={{ fontSize: 12 }}>
                    Continuar logado
                  </Typography>
                </Grid>

                <Button
                  //disabled={loading}
                  type="submit"
                  variant="contained"
                  sx={{
                    width: "35%",
                    background: "linear-gradient(to bottom, #0048FA, #1C2439)",
                    color: "white",
                    fontWeight: "bold",
                    borderRadius: "15px",
                    margin: "20px auto",
                  }}
                >
                  Login
                  {loading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: green[500],
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                </Button>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
