import React, { useState, useEffect } from 'react';
import './OwnerSelect.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { toast } from "react-toastify";

const OwnerSelect = (props) => {
  // //("propsowner", props)

  const [selectedName, setSelectedName] = useState('');
  const [name, setName] = useState('');
  // //(props.id);
  // //(selectedName)

  const [owner, setOwner] = React.useState();


  // const getOwner = async (event) => {
  //   try {
  //     const response = await fetch(
  //       `${process.env.REACT_APP_API_URL}/members`,
  //       {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/x-www-form-urlencoded",
  //         },
  //       }
  //     );
  //     if (response.ok === false) {
  //       throw new Error(response.statusText);
  //     }
  //     const allOwner = await response.json();
  //     setOwner(allOwner[0]);
  //   } catch (error) {
  //     //("ERROR")
  //     toast.error("Erro ao recarregar projetos.");
  //   }
  // };

  // React.useEffect(() => {
  //   getOwner();
  // }, []);

  const updateOwner = async (event) => {
    setSelectedName(event.target.value);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/members`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${props.user.token}`,
          },
          body: JSON.stringify({
            ownerId: event.target.value,
            projectId: props.id,
            /* projectInputStatus:  */
          }),
        }

      );
      //(response)

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      toast.success("Owner atualizado com sucesso.");
    } catch (error) {
      toast.error("Erro ao atualizar o Owner do projeto.");
    }
  };

  useEffect(() => {
    setSelectedName(props.value);
  }, [props.value]);

  useEffect(() => {
    setName(selectedName);
  }, [])
  useEffect(() => {
    setOwner(props.owners)
  }, [props.owners]);


  let items = !!owner ? owner : props.owners;

  const filteredItems = items.filter((item) => item.PROJECT_ID === props.id);
  console.log("props: ", filteredItems);

  // //('selected', selectedName)
  return (
    <>
    {props.isAdmin ?
      <>
      <div className="select-wrap" style={{backgroundColor: "#FFFFFF"}}>
        <select onChange={updateOwner}>
          <option value="0">Selecione</option>
          {filteredItems.map((item) => {
            return (
              <option selected={selectedName == item.USER_ID ? true : false} key={`ownerS-${item.id}`} value={item.USER_ID}>
                {item.FULL_NAME}
              </option>
            )
          }           
          )}
        </select>
      </div>
      </>
      :
      <div className="select-wrap" style={{ backgroundColor: "#FFFFFF" }}>
        {filteredItems.map((item) => {
          if (selectedName === item.USER_ID) {
            return <span key={`ownerS-${item.id}`}>{item.FULL_NAME}</span>;
          }
          return null;
        })}
      </div>
      
    }
    </>
  );
};

export default OwnerSelect;
