import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import "./CostsTable.css";

const ValuesInput = ({ id, handleValueChange, index, costs, rowId, user, isAdmin }) => {
  const [value, setValue] = useState("");
  const [isEditing, setIsEditing] = useState(false); // Controla o modo de edição

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setValue(Number(newValue));
    handleValueChange(id, newValue, index, rowId);
  };


  const initialValue = costs.find((cost) => cost.ID_VALUE === id)?.VALUE || value;

  return (
    <Box
      component="form"
      sx={{
        width: "100%",
      }}
      noValidate
      autoComplete="off"
    >
      {isAdmin ? ( 
        <TextField
          index={index}
          id={id}
          variant="outlined"
          type="number"
          value={value || initialValue || ""}
          onChange={handleInputChange}
         
          autoFocus
        />
      ) : (
        <div className="textfieldd">
          {value || initialValue || ""}
         
        </div>
      )}
    </Box>
  );
};

export default ValuesInput;
