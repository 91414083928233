import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import TableCard from "./TableCard";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Fab from "@mui/material/Fab";
import EditIcon from "@mui/icons-material/Edit";
import "./OutlinedCard.css";
import Modal from "@mui/material/Modal";
import { Button, MenuItem, Select, TextField } from "@mui/material";
import { adminId } from "../../../src/constants";

function OutlinedCard(props) {
  const { id } = useParams();
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    props.setSelectedMembers(value);
  };

  const onSaveInput = async (index) => {
    setLoading(true);

    try {
      const input = props.inputs[index];
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/updateInputs`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${props.user.token}`,
          },
          body: JSON.stringify({
            projectId: id,
            canaisI: input.CANAIS_INTERNOS,
            freqI: input.FREQ_INTERNA,
            contI: input.CONTEUDO_INTERNO,
            canaisE: input.CANAIS_EXTERNOS,
            freqE: input.FREQ_EXTERNA,
            contE: input.CONTEUDO_EXTERNO,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      props.setInputs((prevInputs) => {
        const updatedInputs = [...prevInputs];
        updatedInputs[index] = input;
        return updatedInputs;
      });

      toast.success("Conteúdo atualizado com sucesso.");
    } catch (error) {
      console.log(error);
      toast.error("Erro ao atualizar conteúdo.");
    } finally {
      setEditMode(false);
      setLoading(false);
    }
  };

  const handleEditButtonClick = (id) => {
    setEditMode((prevEditModes) => ({
      ...prevEditModes,
      [id]: true,
    }));
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <div>
            <div style={{ marginBottom: "10px" }}>
              <Typography
                className="typography"
                variant="h5"
                component="div"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "0.875rem",
                  fontWeight: "bolder",
                  width: "33vw",
                }}
              >
                Membros do Projeto
                {props.isAdmin && (
                  <Fab
                    color="primary"
                    aria-label="add"
                    size="small"
                    sx={{ marginBottom: 0, marginLeft: "10px", zIndex: "10" }}
                    onClick={props.handleOpenModal}
                    title="Selecionar membros do projeto"
                  >
                    <EditIcon />
                  </Fab>
                )}
              </Typography>
            </div>

            <Card
              sx={{
                maxWidth: "33vw",
                minWidth: "33vw",
                margin: 0,
              }}
              variant="outlined"
            >
              <CardContent
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "10px",
                  maxHeight: "650px",
                  overflow: 'scroll',
                  overflowY: "auto",
                  overflowX: "visible",
                  width: "100%",

                }}
              >
                <TableCard
                  setSelectedMembers={props.setSelectedMembers}
                  selectedObjects={props.selectedObjects}
                  user={props.user}
                  nomes={props.nomes}
                  selectedMembers={props.selectedMembers}
                  isAdmin={!!props.user && props.user.id_user === adminId}
                />
              </CardContent>
            </Card>
          </div>
        </Grid>
        {props.inputs.map((input, i) => (
          <Grid item xs={8} key={i}>
            <div className="card12">
              <div>
                <Typography
                  className="typography titles"
                  variant="h5"
                  component="div"
                >
                  Canais Internos
                </Typography>
                <Card
                  sx={{
                    minHeight: 300,
                    maxWidth: "400px",
                    minWidth: "400px",
                    margin: "20px",
                  }}
                  variant="outlined"
                >
                  <CardContent
                    className="cardContent"
                  >
                    {editMode[props.inputIds[0]] ? (
                      <div>
                        <TextField
                          value={input.CANAIS_INTERNOS || ""}
                          multiline
                          maxRows={10}
                          sx={{ minWidth: "100%", maxHeight: "300px" }}
                          onChange={(e) =>
                            props.setInputs((prevInputs) => {
                              const updatedInputs = [...prevInputs];
                              updatedInputs[i] = {
                                ...updatedInputs[i],
                                CANAIS_INTERNOS: e.target.value,
                              };
                              return updatedInputs;
                            })
                          }
                        />
                        <Button
                          id={props.inputIds[0]}
                          onClick={() => onSaveInput([0])}
                        >
                          Salvar
                        </Button>
                        <Button
                          id={props.inputIds[i]}
                          onClick={() =>
                            setEditMode((prevEditModes) => ({
                              ...prevEditModes,
                              [props.inputIds[0]]: false,
                            }))
                          }
                        >
                          Cancelar
                        </Button>
                      </div>
                    ) : (
                      <div>
                        <Typography>{input.CANAIS_INTERNOS}</Typography>
                        {props.isAdmin && (
                          <Button
                            onClick={() =>
                              handleEditButtonClick(props.inputIds[0])
                            }
                          >
                            Editar
                          </Button>
                        )}
                      </div>
                    )}
                  </CardContent>
                </Card>
              </div>

              <div>
                <Typography
                  className="typography titles"
                  variant="h5"
                  component="div"
                >
                  Frequência Interna
                </Typography>
                <Card
                  cars
                  sx={{
                    minHeight: 300,
                    maxWidth: "400px",
                    minWidth: "400px",
                    margin: "20px",
                  }}
                  variant="outlined"
                >
                  <CardContent
                   className="cardContent"
                  >
                    {editMode[props.inputIds[1]] ? (
                      <div>
                        <TextField
                          value={input.FREQ_INTERNA}
                          multiline
                          maxRows={10}
                          sx={{ minWidth: "100%", maxHeight: "300px" }}
                          onChange={(e) =>
                            props.setInputs((prevInputs) => {
                              const updatedInputs = [...prevInputs];
                              updatedInputs[i] = {
                                ...updatedInputs[i],
                                FREQ_INTERNA: e.target.value,
                              };
                              return updatedInputs;
                            })
                          }
                        />
                        <Button
                          id={props.inputIds[1]}
                          onClick={() => onSaveInput([0])}
                        >
                          Salvar
                        </Button>
                        <Button
                          id={props.inputIds[1]}
                          onClick={() =>
                            setEditMode((prevEditModes) => ({
                              ...prevEditModes,
                              [props.inputIds[1]]: false,
                            }))
                          }
                        >
                          Cancelar
                        </Button>
                      </div>
                    ) : (
                      <div>
                        <Typography>{input.FREQ_INTERNA}</Typography>
                        {props.isAdmin && (
                          <Button
                            onClick={() =>
                              handleEditButtonClick(props.inputIds[1])
                            }
                          >
                            Editar
                          </Button>
                        )}
                      </div>
                    )}
                  </CardContent>
                </Card>
              </div>

              <div>
                <Typography
                  className="typography titles"
                  variant="h5"
                  component="div"
                >
                  Conteúdo Interno
                </Typography>

                <Card
                  sx={{
                    minHeight: 300,
                    maxWidth: "400px",
                    minWidth: "400px",
                    margin: "20px",
                  }}
                  variant="outlined"
                >
                  <CardContent
                    className="cardContent"
                  >
                    {editMode[props.inputIds[2]] ? (
                      <div>
                        <TextField
                          value={input.CONTEUDO_INTERNO}
                          multiline
                          maxRows={10}
                          sx={{ minWidth: "100%", maxHeight: "300px" }}
                          onChange={(e) =>
                            props.setInputs((prevInputs) => {
                              const updatedInputs = [...prevInputs];
                              updatedInputs[i] = {
                                ...updatedInputs[i],
                                CONTEUDO_INTERNO: e.target.value,
                              };
                              return updatedInputs;
                            })
                          }
                        />
                        <Button
                          id={props.inputIds[2]}
                          onClick={() => onSaveInput([0])}
                        >
                          Salvar
                        </Button>
                        <Button
                          id={props.inputIds[2]}
                          onClick={() =>
                            setEditMode((prevEditModes) => ({
                              ...prevEditModes,
                              [props.inputIds[2]]: false,
                            }))
                          }
                        >
                          Cancelar
                        </Button>
                      </div>
                    ) : (
                      <div>
                        <Typography>{input.CONTEUDO_INTERNO}</Typography>
                        {props.isAdmin && (
                          <Button
                            onClick={() =>
                              handleEditButtonClick(props.inputIds[2])
                            }
                          >
                            Editar
                          </Button>
                        )}
                      </div>
                    )}
                  </CardContent>
                </Card>
              </div>
            </div>

            <div className="card12">
              <div>
                <Typography
                  className="typography titles"
                  variant="h5"
                  component="div"
                >
                  Canais Externos
                </Typography>
                <Card
                  sx={{
                    minHeight: 300,
                    maxWidth: "400px",
                    minWidth: "400px",
                    margin: "20px",
                  }}
                  variant="outlined"
                >
                  <CardContent
                   className="cardContent"
                  >
                    {editMode[props.inputIds[3]] ? (
                      <div>
                        <TextField
                          value={input.CANAIS_EXTERNOS}
                          multiline
                          maxRows={10}
                          sx={{ minWidth: "100%", maxHeight: "300px" }}
                          onChange={(e) =>
                            props.setInputs((prevInputs) => {
                              const updatedInputs = [...prevInputs];
                              updatedInputs[i] = {
                                ...updatedInputs[i],
                                CANAIS_EXTERNOS: e.target.value,
                              };
                              return updatedInputs;
                            })
                          }
                        />
                        <Button
                          id={props.inputIds[3]}
                          onClick={() => onSaveInput([0])}
                        >
                          Salvar
                        </Button>
                        <Button
                          id={props.inputIds[2]}
                          onClick={() =>
                            setEditMode((prevEditModes) => ({
                              ...prevEditModes,
                              [props.inputIds[3]]: false,
                            }))
                          }
                        >
                          Cancelar
                        </Button>
                      </div>
                    ) : (
                      <div>
                        <Typography>{input.CANAIS_EXTERNOS}</Typography>
                        {props.isAdmin && (
                          <Button
                            onClick={() =>
                              handleEditButtonClick(props.inputIds[3])
                            }
                          >
                            Editar
                          </Button>
                        )}
                      </div>
                    )}
                  </CardContent>
                </Card>
              </div>

              <div>
                <Typography
                  className="typography titles"
                  variant="h5"
                  component="div"
                >
                  Frequência Externa
                </Typography>
                <Card
                  sx={{
                    minHeight: 300,
                    maxWidth: "400px",
                    minWidth: "400px",
                    margin: "20px",
                  }}
                  variant="outlined"
                >
                  <CardContent
                    className="cardContent"
                  >
                    {editMode[props.inputIds[4]] ? (
                      <div>
                        <TextField
                          value={input.FREQ_EXTERNA}
                          multiline
                          maxRows={10}
                          sx={{ minWidth: "100%", maxHeight: "300px" }}
                          onChange={(e) =>
                            props.setInputs((prevInputs) => {
                              const updatedInputs = [...prevInputs];
                              updatedInputs[i] = {
                                ...updatedInputs[i],
                                FREQ_EXTERNA: e.target.value,
                              };
                              return updatedInputs;
                            })
                          }
                        />
                        <Button
                          id={props.inputIds[4]}
                          onClick={() => onSaveInput([0])}
                        >
                          Salvar
                        </Button>
                        <Button
                          id={props.inputIds[2]}
                          onClick={() =>
                            setEditMode((prevEditModes) => ({
                              ...prevEditModes,
                              [props.inputIds[4]]: false,
                            }))
                          }
                        >
                          Cancelar
                        </Button>
                      </div>
                    ) : (
                      <div>
                        <Typography>{input.FREQ_EXTERNA}</Typography>
                        {props.isAdmin && (
                          <Button
                            onClick={() =>
                              handleEditButtonClick(props.inputIds[4])
                            }
                          >
                            Editar
                          </Button>
                        )}
                      </div>
                    )}
                  </CardContent>
                </Card>
              </div>

              <div>
                <Typography
                  className="typography titles"
                  variant="h5"
                  component="div"
                >
                  Conteúdo Externo
                </Typography>

                <Card
                  sx={{
                    minHeight: 300,
                    maxWidth: "400px",
                    minWidth: "400px",
                    margin: "20px",
                  }}
                  variant="outlined"
                >
                  <CardContent
                    className="cardContent"
                  >
                    {editMode[props.inputIds[5]] ? (
                      <div>
                        <TextField
                          value={input.CONTEUDO_EXTERNO}
                          multiline
                          maxRows={10}
                          sx={{ minWidth: "100%", maxHeight: "300px" }}
                          onChange={(e) =>
                            props.setInputs((prevInputs) => {
                              const updatedInputs = [...prevInputs];
                              updatedInputs[i] = {
                                ...updatedInputs[i],
                                CONTEUDO_EXTERNO: e.target.value,
                              };
                              return updatedInputs;
                            })
                          }
                        />
                        <Button
                          id={props.inputIds[4]}
                          onClick={() => onSaveInput([0])}
                        >
                          Salvar
                        </Button>
                        <Button
                          id={props.inputIds[2]}
                          onClick={() =>
                            setEditMode((prevEditModes) => ({
                              ...prevEditModes,
                              [props.inputIds[5]]: false,
                            }))
                          }
                        >
                          Cancelar
                        </Button>
                      </div>
                    ) : (
                      <div>
                        <Typography>{input.CONTEUDO_EXTERNO}</Typography>
                        {props.isAdmin && (
                          <Button
                            onClick={() =>
                              handleEditButtonClick(props.inputIds[5])
                            }
                          >
                            Editar
                          </Button>
                        )}
                      </div>
                    )}
                  </CardContent>
                </Card>
              </div>
            </div>
          </Grid>
        ))}
      </Grid>

      <Modal
        open={props.isModalOpen}
        onClose={props.handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 100,
            p: 4,
            height: 220,
          }}
        >
          <Typography
            id="modal-title"
            variant="h6"
            component="h2"
            sx={{ textAlign: "center" }}
          >
            Selecione os membros:
          </Typography>
          <div className="selectButton">
            <Select
              multiple
              sx={{ textAlign: "center", width: "100%", marginTop: "20px" }}
              inputlabel="Selecione"
              value={props.selectedMembers}
              onChange={handleChange}
            >
              {props.nomes.map((nome, index) => (
                <MenuItem
                  value={nome.FULL_NAME}
                  key={nome}
                  selected={
                    props.initialSelectedMembers /* .includes(nome.FULL_NAME) */
                  }
                >
                  {nome.FULL_NAME}
                </MenuItem>
              ))}
            </Select>
            <Button sx={{ marginTop: "20px" }} onClick={props.handleSaveAndClose}>
              Salvar
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
}
export default OutlinedCard;
