import * as React from "react";
import { useState, useEffect } from "react";
import "./StatusSelect.css";
import OwnerSelect from "./OwnerSelect.js";
import ManagerSelect from "./ManagerSelect.js";
import StatusSelect from "./StatusSelect";
import DeadlineSelect from "./DeadlineSelect";
import PriorityChange from "./PriorityChange";
import GeoColorChange from "./GeoColorChange";
import ChildCell from "./ChildCell";

const sortProjects = (projects) => {
  const sortedProjects = [];
  const parentProjects = [];

  projects.forEach((project) => {
    const match = project.NAME.match(/\(id:(\d+)\)/);
    if (match) {
      const parentId = parseInt(match[1]);
      const parentProject = projects.find((p) => p.ID === parentId);
      if (parentProject) {
        parentProject.children = parentProject.children || [];

        const exists = parentProject.children.some(
          (child) => child.ID === project.ID
        );

        if (!exists) {
          parentProject.children.push(project);
        }
      }
    } else {
      sortedProjects.push(project);
    }
  });

  parentProjects.forEach((parentProject) => {
    sortedProjects.push(parentProject);
  });

  return sortedProjects;
};

export default function BasicTable(props) {
  const onSave = async (value, project) => {
    props.onSave(value, project);
  };

  const [newOrderProjects, setNewOrderProjects] = useState([]);

  useEffect(() => {
    const sortedProjects = sortProjects(props.projects);
    setNewOrderProjects(sortedProjects);
  }, [props.projects]);

  const TextWithHtmlTags = ({ text }) => {
    if (!text) {
      return "";
    }

    const decodedText = text
      .replace(/\[P\]/g, "<p>")
      .replace(/\[\/P\]/g, "</p>")
      .replace(/&lt;/g, "<")
      .replace(/&lt;\/;/g, "</")
      .replace(/&gt;/g, ">")
      .replace(/&#39;/g, "'")
      .replace(/&#34;/g, '"');

    // .replace(/&lt;\/b&gt;/g, "</b>")
    // .replace(/&lt;b&gt;/g, "<b>");
    return <div dangerouslySetInnerHTML={{ __html: decodedText }} />;
  };

  const formataKeywords = (keywords) => {
    const wordsArray = keywords.split(",");
    // seleciona somente as palavras que não começam com "entre_"
    const entreWords = wordsArray.filter((word) => !word.startsWith("entre_"));
    // remove "entre_" de cada palavra do array
    const words = entreWords.map((word) =>
      word.replace("entre_", "").toUpperCase()
    );

    let formatedKeywords = words.join("<br />");
    return <span dangerouslySetInnerHTML={{ __html: formatedKeywords }} />;
  };

  let somenteUm = [newOrderProjects[0]];

  const toggleExpanded = (id) => {
    setNewOrderProjects((prevProjects) => {
      return prevProjects.map((item) => {
        if (item.ID === id) {
          return { ...item, isExpanded: !item.isExpanded };
        }
        console.log(item.ID, id, "idddddd");
        return item;
      });
    });
  };

  
  return (
    <>
      <section className="table-wrap">
        <div className="table-projetos">
          <div className="table-header">
            <ul>
              <li className="table-item tab-col-1"></li>
              {props.isAdmin ? (
                <>
                  <li className="header-item tab-col-3">
                    <p>COR</p>
                  </li>
                  <li className="header-item tab-col-3">
                    <p>GP</p>
                  </li>
                </>
              ) : (
                <ul>
                  <li className="header-item tab-col-3">
                    <p></p>
                  </li>
                  <li className="header-item tab-col-3">
                    <p></p>
                  </li>
                  <li className="header-item tab-col-3">
                    <p></p>
                  </li>
                </ul>
              )}
              <li className="header-item tab-col-15">
                <p>Nome do Projeto</p>
              </li>
              <li className="header-item tab-col-15">
                <p>Descrição</p>
              </li>
              <li className="header-item tab-col-5">
                <p>Status</p>
              </li>
              <li className="header-item tab-col-10">
                <p>Prazo</p>
              </li>
              <li className="header-item tab-col-30">
                <p>
                  Status Resumido{" "}
                  <p style={{ fontWeight: "200", fontSize: "10px" }}>
                    Obs.: Passe o mouse sobre o texto para visualizá-lo
                    completamente
                  </p>{" "}
                </p>
              </li>
              <li className="header-item tab-col-10">
                <p>Owner</p>
              </li>

              <li className="header-item tab-col-10">
                <p>GP</p>
              </li>
              <li className="header-item tab-col-5">
                <p className="rpc">Comunicação | Responsabilidades | Custos</p>
              </li>
            </ul>
          </div>
          {newOrderProjects.map((value) => {
            if (!value) {
              return "";
            } else {
              return (
                <>
                  <div
                    className="table-line"
                    style={{
                      justifyContent: "flex-start",
                      backgroundColor: !!value.GEO_COLOR
                        ? `${value.GEO_COLOR}`
                        : "#f1f0f0",
                    }}
                  >
                    <ul>
                      <li
                        className="table-item tab-col-1"
                        onClick={() => toggleExpanded(value.ID)}
                      >
                        {value.children && value.children.length > 0 ? (
                          <span style={{ fontSize: "10px" }}>
                            {value.isExpanded ? (
                              <span title="Fechar subprojetos">▼</span>
                            ) : (
                              <span title={`Ver sub-projetos de ${value.NAME}`}>
                                ▶
                              </span>
                            )}
                          </span>
                        ) : (
                          <span
                            style={{
                              fontSize: "10px",
                              color: !!value.GEO_COLOR
                                ? `${value.GEO_COLOR}`
                                : "#f1f0f0",
                            }}
                          >
                            {" "}
                            ▶
                          </span>
                        )}
                      </li>
                      {props.isAdmin ? (
                        <>
                          <li className="table-item tab-col-3">
                            <GeoColorChange
                              id={value.ID}
                              key={`geoColor-${value.ID}`}
                              value={value.GEO_COLOR}
                              user={props.user}
                            />
                          </li>
                          <li className="table-item tab-col-3">
                            <PriorityChange
                              id={value.ID}
                              key={`priority-${value.ID}`}
                              value={value.GENERAL_PRIORITY}
                              user={props.user}
                            />
                          </li>
                        </>
                      ) : (
                        <li className="table-item tab-col-3">
                          <span className="tasks-grid-avatar ui-icon ui-icon-common-user-group">
                            <a
                              title={`Projeto ${value.NAME}`}
                              href={`https://projetos.pmovil.com.br/workgroups/group/${value.ID}/tasks/`}
                            >
                              <img
                                style={{
                                  width: "30px",
                                  minWidth: "30px",
                                  borderRadius: "50px",
                                }}
                                src={`https://projetos.pmovil.com.br/upload/${value.IMG_PATH}/${value.IMG_NAME}`}
                              />
                            </a>
                          </span>
                        </li>
                      )}
                      <li className="table-item tab-col-15">
                        <p className="descripton-text ">
                          <a
                            title={`Projeto ${value.NAME}`}
                            href={`https://projetos.pmovil.com.br/workgroups/group/${value.ID}/tasks/`}
                            style={{ textAlign: "left" }}
                          >
                            {value.NAME}
                          </a>
                        </p>
                        {value.NAME && (
                          <span
                            className="description-tooltips"
                            style={{ marginTop: "40px" }}
                          >
                            <TextWithHtmlTags text={value.NAME} />
                          </span>
                        )}
                      </li>
                      <li className="table-item tab-col-15">
                        <p className="descripton-text ">
                          <TextWithHtmlTags text={value.DESCRIPTION} />
                        </p>
                        {value.DESCRIPTION && (
                          <span className="description-tooltips">
                            <TextWithHtmlTags text={value.DESCRIPTION} />
                          </span>
                        )}
                      </li>
                      <li className="table-item tab-col-5">
                        <StatusSelect
                          id={value.ID}
                          key={`statusSelect-${value.ID}`}
                          value={value.STATUS_COLOR}
                          user={props.user}
                          isAdmin={props.isAdmin}
                        />
                      </li>
                      <li className="table-item tab-col-10">
                        {!!value && (
                          <DeadlineSelect
                            id={value.ID}
                            key={`deadline-${value.ID}`}
                            value={value.DEADLINE}
                            user={props.user}
                            isAdmin={props.isAdmin}
                          />
                        )}
                      </li>
                      <li className="table-item tab-col-30">
                        <p className="descripton-text">
                          <TextWithHtmlTags text={value.LAST_FEED} />
                        </p>
                        {value.LAST_FEED && (
                          <span className="description-tooltips">
                            <TextWithHtmlTags text={value.LAST_FEED} />
                          </span>
                        )}
                      </li>
                      <li className="table-item tab-col-10">
                        <OwnerSelect
                          id={value.ID}
                          key={`owner-${value.ID}`}
                          value={value.OWNER_ID}
                          user={props.user}
                          isAdmin={props.isAdmin}
                          owners={props.owners}
                        />
                      </li>

                      <li
                        className="table-item tab-col-10"
                        style={{ textAlign: "center" }}
                      >
                        <ManagerSelect
                          id={value.ID}
                          key={`manager-${value.ID}`}
                          value={value.PROJECT_MANAGER_ID}
                          user={props.user}
                          isAdmin={props.isAdmin}
                          managers={props.owners}

                        />
                      </li>
                      <li className="table-item preview tab-col-5">
                        <a
                          className="table-previews"
                          href={`/comunicacao/${value.ID}`}
                        >
                          {/* <a className="table-previews" href="/emconstrucao/" title='Comunicação'> */}

                          <img src="images/plano-comunicacao.png" alt="" />
                        </a>
                        <a
                          className="table-previews"
                          href={`/responsabilidade/${value.ID}`}
                          title="Matriz de Responsabilidades"
                        >
                          {/* <a className="table-previews" href="/emconstrucao/"> */}

                          <img
                            src="images/matrix-responsabilidade.png"
                            alt=""
                          />
                        </a>
                        <a
                          className="table-previews"
                          href={`/custos/${value.ID}`}
                          title="Matriz de Custos"
                        >
                          {/* <a className="table-previews" href="/emconstrucao/" title='Previsão Custos'> */}
                          <img src="images/previsao-custos.png" alt="" />
                        </a>
                      </li>
                    </ul>
                  </div>
                  {!!value.children &&
                    value.isExpanded &&
                    value.children.map((child) => {
                      return (
                        <>
                          <ChildCell
                            id={child.ID}
                            key={`child-${child.ID}`}
                            project={child}
                            user={props.user}
                            isAdmin={props.isAdmin}
                          />
                        </>
                      );
                    })}
                </>
              );
            }
          })}
        </div>
      </section>
    </>
  );
}
/* function debounce(func, wait) {
  let timeout;
  return function (...args) {
    const context = this;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      func.apply(context, args);
    }, wait);
  };
} */
