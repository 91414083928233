import React, { useState, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
// import '../StatusSelect.css';
import { toast } from "react-toastify";
import UploadService from "../services/UploadFile.js";

const UpdateDate = (props) => {

  // console.log('props status: ', props)
    const getUpdateDate = () => {
        UploadService.getUpdateDate().then((response) => {
          console.log(" UploadService.getUpdateDate resp: ",response )
            setDateUpdate(response);
        });
    }

  const [dateUpdate, setDateUpdate] = useState();
  


  const handleUpdateDateChange = (data) => {
    const updateDate = data.target.value;

        setDateUpdate(updateDate);

        UploadService.updateDate(updateDate);
        
        toast.success("Data atualizada com sucesso.");
  };

  useEffect(() => {
    getUpdateDate();
  }, []);


  return (
    <>
      {props.isAdmin ?
        <>
        <div ame="select-wrap" style={{width: "100%", textAlign: "center", color: "red"}}>
        Ultima data atualização:
          <input type="text"
                value={dateUpdate}
                onChange={(event) => setDateUpdate(event.target.value)}
                onBlur={handleUpdateDateChange}
                className="color-selector"
                style={{ fontFamily: "Poppins, sans-serif", fontSize: "12px", fontWeight: "800", width: "20%", justifySelf: "center"}}
            />
        </div>
      </>
      :
        <div className="select-wrap" style={{width: "100%", textAlign: "center", color: "red"}}>
         Ultima data atualização: <b style={{ fontFamily: "Poppins, sans-serif", fontSize: "12px", fontWeight: "800", width: "20%", justifySelf: "center"}}>{dateUpdate}</b>
        </div>
      }
    
    </>
  );
};

export default UpdateDate;
