import React, { Component } from "react";
import { adminId } from "../constants";
import Modal from "react-modal";
import UploadService from "../services/UploadFile.js";
import "./TableListUpload.css";

Modal.setAppElement("#root");

export default class UploadFiles extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedFiles: '',
      currentFile: '',
      progress: 0,
      message: "",
      fileInfos: [],
      showModal: false, // Variável de estado para controlar a exibição do modal
      fileToDelete: "", // Nome do arquivo a ser excluído
    };
    
  }
    componentDidMount() {
        this.getFiles();
    }
    getFiles(){
        UploadService.getFiles().then((response) => {
            const fileInfosArray = Object.entries(response).map(([key, value]) => ({ id: key, name: value }));
            this.setState({
              fileInfos: fileInfosArray,
            });
          });
    }
    openModal = (fileName) => {
        this.setState({ showModal: true, fileToDelete: fileName });
    };
    closeModal = () => {
        this.setState({ showModal: false, fileToDelete: "" });
    };

    confirmDelete = () => {
        const { fileToDelete } = this.state;
        const resp = UploadService.removeFile(fileToDelete);
        this.getFiles();
        this.closeModal();
      };
    

   render() {
    const {
      fileInfos,
      showModal,
    } = this.state;
    const urlUpload = `${process.env.REACT_APP_API_URL}/upload`;

    return (
        <section className="table-wrap" style={{width: "50%"}}>
            <div className="table-projetos">
                <div className="table-header"  style={{paddingLeft: "3%"}}>
                    <ul>
                        <li className="header-item ">
                            <p>Abaixo arquivos e atas apresentados nas reuniões de status dos projetos e comitê:</p>
                        </li>
                        {!!this.props.user && this.props.user.id_user == adminId &&
                            <li className="header-item">
                                Remover
                            </li>
                        }
                    </ul>
                </div>
                {fileInfos && fileInfos.length > 0 &&
                fileInfos.map((file) => (
                    <div className="table-line" style={{paddingLeft: "3%"}}>
                        <ul>
                            <li className="table-item" >
                                <a href={`${urlUpload}/uploads/${file.name}`}>{file.name}</a>
                            </li>
                            {!!this.props.user && this.props.user.id_user == adminId &&
                                <li className="table-item">
                                    <button onClick={() => this.openModal(file.name)}>X</button>
                                </li>
                            }

                        </ul>
                    </div>
                ))}
                 {/* Modal de confirmação de exclusão */}
                <Modal
                    isOpen={showModal}
                    onRequestClose={this.closeModal}
                    className="custom-modal"
                    overlayClassName="custom-modal-overlay"                  
                    contentLabel="Confirm Delete Modal"
                >
                    <h2>Confirma Exclusão</h2>
                    <p>Tem certeza que quer remover o arquivo ?</p>
                    <button onClick={this.confirmDelete}>Sim</button>
                    <button onClick={this.closeModal}>Não</button>
                </Modal>
        </div>
        </section>
    );

  }
}
