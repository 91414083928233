import { Button, Grid, Typography } from '@mui/material';
import React from 'react'
import TableResp from '../../components/Responsabilidade/TableResp.js';
import { adminId } from "../../constants";
import Header from '../../components/Header/Header.js';

function Responsabilidade(props) {
    return (
        <>
        <Header  onLogout={props.onLogout} user={props.user}/>
        <div className="Comunicacao">
            <div >
             <TableResp
              isAdmin={!!props.user && props.user.id_user === adminId}
              user={props.user}/>
            </div>
        </div>
        </>
    )
}
export default Responsabilidade
