import React, { useState, useEffect } from 'react';
import './OwnerSelect.css';
import { toast } from "react-toastify";

const ManagerSelect = (props) => {
  // console.log("propsmanager", props)

  const [selectedName, setSelectedName] = useState('');
  const [name, setName] = useState('');

  const [manager, setManager] = React.useState([]);

  // const getManager = async (event) => {
  //   try {
  //     const response = await fetch(
  //       `${process.env.REACT_APP_API_URL}/members`,
  //       {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/x-www-form-urlencoded",
  //         },
  //       }
  //     );
  //     if (response.ok === false) {
  //       throw new Error(response.statusText);
  //     }
  //     const allManager = await response.json();
  //     setManager(allManager[0]);
  //   } catch (error) {
  //     console.log("ERROR")
  //     toast.error("Erro ao recarregar projetos.");
  //   }
  // };

  // React.useEffect(() => {
  //   getManager();
  // }, []);

  const updateManager = async (event) => {
    setSelectedName(event.target.value);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/members`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${props.user.token}`,
          },
          body: JSON.stringify({
            managerId: event.target.value,
            projectId: props.id,
            /* projectInputStatus:  */
          }),
        }

      );
      console.log(response)

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      toast.success("Manager atualizado com sucesso.");
    } catch (error) {
      toast.error("Erro ao atualizar o Manager do projeto.");
    }
  };

  useEffect(() => {
    setSelectedName(props.value);
  }, [props.value]);

  useEffect(() => {
    setName(selectedName);
  }, [])
  useEffect(() => {
    setManager(props.managers);
  }, [props.managers])

  let items = !!manager ? manager : props.managers;

  const filteredItems = items.filter((item) => item.PROJECT_ID === props.id);

  return (
    <>
    {props.isAdmin ?
      <>
      <div className="select-wrap" style={{backgroundColor: "#FFFFFF"}}>
        <select onChange={updateManager}>
          <option value="0">Selecione</option>
          {filteredItems.map((item) => {
            return (
              <option selected={selectedName == item.USER_ID ? true : false} key={`managerS-${item.id}`} value={item.USER_ID}>
                {item.FULL_NAME}
              </option>
            )
          }           
          )}
        </select>
      </div>
      </>
      :
      <>
      <div className="select-wrap" style={{ backgroundColor: "#FFFFFF" }}>
        
        {filteredItems.map((item) => {
          console.log(item.id, 'item id')
          if (parseInt(props.value) === parseInt(item.USER_ID)) {
            return <span key={`managerS-${item.id}`}>{item.FULL_NAME}</span>;
          }
          return null;
        })}
      </div>
      </>
    }
    </>
  );
};

export default ManagerSelect;
