import React, { Component } from "react";
import UploadService from "../services/UploadFile.js";
export default class UploadFiles extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedFiles: '',
      currentFile: '',
      progress: 0,
      message: "",
    };
    this.selectFile = this.selectFile.bind(this); // Ligação do método selectFile
    this.upload = this.upload.bind(this); // Ligação do método upload

    
  }

  selectFile(event) {
    this.setState({
      selectedFiles: event.target.files,
    });
  }

  upload() {
    let currentFile = this.state.selectedFiles[0];

    this.setState({
      currentFile: currentFile,
    });

    UploadService.upload(currentFile).then((response) => {
      //(response)
      
      this.setState({
        message: !!response.data ? "" : response.response.data,
        currentFile: undefined,
      });
      if(response.status == 200){
          window.location.reload();
      }

    }).catch(() => {
        this.setState({
          message: "Could not upload the file!",
          currentFile: undefined,
        });
    });
  }

  render() {
    const {
      selectedFiles,
      currentFile,
      message,
    } = this.state;
    
    return (
      <section className="filter-search" style={{width: "50%"}}>
        <div className="filter-search-wrap" style={{flexDirection: "column"}}>
          <div>
            <label className="btn btn-default">
              <input type="file" maxLength={10 * 1024 * 1024} onChange={this.selectFile} />
            </label>
          </div>
          <div>
            <button className="btn btn-success"
              disabled={!selectedFiles}
              onClick={this.upload}
            >
              Upload
            </button>
            <div className="alert alert-light" role="alert">
              <b>{message}</b>
            </div>
          </div>
        </div>
      </section>
    );

  }
}
