import React, { useState, useEffect } from 'react';
import NumberPicker from "react-widgets/NumberPicker";
import './StatusSelect.css';
import { toast } from "react-toastify";
import "react-widgets/styles.css";

const PriorityChange = (props) => {
  // console.log('props status: ', props)

  const [priority, setPriority] = useState(props.value);
  
  const handlePriorityChange = async (data) => {
    //event.target.value me retorna o valor que está dentro de "color" (a cor selecionada)
    const priority = data.target.value;

    console.log(data, 'DATA')
    setPriority(priority);


    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/priority`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${props.user.token}`,
          },
          body: JSON.stringify({
            projectPriority: priority,
            projectId: props.id,
          }),
        }
      );

      console.log(response)

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      toast.success("Prioridade atualizada com sucesso.");
    } catch (error) {
      console.log(error);
      toast.error("Erro ao atualizar a prioridade do projeto.");
    }
  };

  useEffect(() => {
    setPriority(props.value)
    
  }, []);

  
  return (
    <div>
      <input  
          type="number" 
          value={priority}
          onChange={(event) => setPriority(event.data)} 
          onBlur={handlePriorityChange}
          style={{ backgroundColor: "#f1f0f0", width: "40px", fontFamily: "Poppins, sans-serif", fontSize: "12px", justifySelf: "left", border: "none", outline: "none" }}
      />
    </div>
    
    
  );
};

export default PriorityChange;
