import { Button, Grid, Typography } from "@mui/material";
import OutlinedCard from "../../components/Comunicacao/OutlinedCard.js";
import { toast } from "react-toastify";
import React from "react";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import { adminId } from "../../constants.js";

function Comunicacao(props) {
  const { id } = useParams();
  const [nomes, setNomes] = useState([]);
  const [inputs, setInputs] = useState([]);
  const [initialSelectedMembers, setInitialSelectedMembers] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [loading, setLoading] = useState([]);
  const inputIds = [1, 2, 3, 4, 5, 6];
  const [selectedObjects, setSelectedObjects] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const getNomes = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/comunicacao/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (response.ok === false) {
        throw new Error(response.statusText);
      }
      const allNames = await response.json();
      setNomes(allNames[0]);
    } catch (error) {
      console.log(error);
      toast.error("Erro ao recarregar projetos.");
    }
  };

  const getInputs = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/listInputs/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (response.ok === false) {
        throw new Error(response.statusText);
      }
      const allInputs = await response.json();
      setInputs(allInputs[0]);
    } catch (error) {
      toast.error("Erro ao recarregar projetos.");
    }
  };

  const getSelectedMembers = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/listSelectedMembers/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (response.ok === false) {
        throw new Error(response.statusText);
      }
      const allNames = await response.json();
      setInitialSelectedMembers(allNames[0]); // Setando os membros selecionados inicialmente
      // Pré-selecionando os membros diretamente em selectedMembers
      const selectedNames = allNames[0].map((obj) => obj.FULL_NAME);
      setSelectedMembers(selectedNames);
    } catch (error) {
      toast.error("Erro ao recarregar projetos.");
    } finally {
      setLoading(false);
    }
  };

  const uniqueItems = nomes.filter(
    (value, index, self) =>
      self.findIndex((item) => item.TITLE === value.TITLE) === index
  );

  const handleSaveAndClose = async () => {
    const selectedObjects = selectedMembers.map((selectedName) => {
      const selectedNameObject = nomes.find(
        (name) => name.FULL_NAME === selectedName
      );
      return {
        USER_ID: selectedNameObject.USER_ID
          ? selectedNameObject.USER_ID
          : selectedName.USER_ID,
        PROJECT_ID: selectedNameObject ? selectedNameObject.PROJECT_ID : null,
        FULL_NAME: selectedNameObject
          ? selectedNameObject.FULL_NAME
          : selectedName,
        EMAIL: selectedNameObject
          ? selectedNameObject.COMUNIC_EMAIL || selectedNameObject.EMAIL
          : null,
        DEPARTMENT: selectedNameObject ? selectedNameObject.DEPARTMENT : null,
      };
    });

    setSelectedObjects(selectedObjects);

    try {
      await updateMembers(selectedObjects);
      toast.success("Membros atualizados com sucesso.");
    } catch (error) {
      toast.error("Erro ao atualizar membros do projeto.");
    } finally {
      handleCloseModal();
    }
  };

  const updateMembers = async (selectedObjects) => {
    setLoading(true);
    for (const obj of selectedObjects) {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/updateMembers`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${props.user.token}`,
          },
          body: JSON.stringify({
            userId: obj.USER_ID,
            userName: obj.FULL_NAME,
            department: obj.DEPARTMENT,
            email: obj.EMAIL,
            projectId: id,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(response.statusText);
      }
    }
    setLoading(false);
  };

  const updateSelectedObjects = () => {
    if (selectedMembers.length > 0 && nomes.length > 0) {
      const selectedObjects = selectedMembers.map((selectedName) => {
        const selectedNameObject = nomes.find(
          (name) => name.FULL_NAME === selectedName
        );

        if (selectedNameObject) {
          return {
            USER_ID: selectedNameObject.USER_ID,
            PROJECT_ID: selectedNameObject.PROJECT_ID,
            FULL_NAME: selectedNameObject.FULL_NAME,
            EMAIL: selectedNameObject.COMUNIC_EMAIL || selectedNameObject.EMAIL,
            DEPARTMENT: selectedNameObject.DEPARTMENT,
          };
        }
      });
      setSelectedObjects(selectedObjects);
    }
  };

  useEffect(() => {
    getNomes();
    getSelectedMembers();
    getInputs();
  }, []);

  useEffect(() => {
    updateSelectedObjects();
  }, [selectedMembers, nomes]);

  return (
    <div className="Comunicacao">
      <Header onLogout={props.onLogout} user={props.user} />
      {uniqueItems.map((nome) => (
        <Typography
          key={id}
          sx={{
            fontFamily: ["'Poppins'", "sans-serif"].join(","),
            fontSize: 16,
            fontWeight: "700",
            color: "##000536",
            textAlign: "center",
            textTransform: "uppercase",
            margin: "20px",
          }}
        >
          Plano de Comunicação Projeto {nome.PROJECT_NAME}
        </Typography>
      ))}

      <div className="cardDiv">
        <OutlinedCard
          selectedObjects={selectedObjects}
          initialSelectedMembers={initialSelectedMembers}
          isModalOpen={isModalOpen}
          handleOpenModal={handleOpenModal}
          handleCloseModal={handleCloseModal}
          updateSelectedObjects={updateSelectedObjects}
          updateMembers={updateMembers}
          handleSaveAndClose={handleSaveAndClose}
          isAdmin={!!props.user && props.user.id_user === adminId}
          user={props.user}
          key={props.user}
          nomes={nomes}
          getNomes={getNomes}
          inputs={inputs}
          getInputs={getInputs}
          setInputs={setInputs}
          getSelectedMembers={getSelectedMembers}
          selectedMembers={selectedMembers}
          setSelectedMembers={setSelectedMembers}
          inputIds={inputIds}
        />
      </div>
    </div>
  );
}
export default Comunicacao;
