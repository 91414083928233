import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

const SelectEstrategico = (props) => {
  const [selectedPriority, setSelectedPriority] = useState(props.value);
  const priorityValues = [
    { value: 1, label: "Muito baixo" },
    { value: 2, label: "Baixo" },
    { value: 3, label: "Médio" },
    { value: 4, label: "Alto" },
    { value: 5, label: "Muito Alto" },
  ];

  useEffect(() => {
    setSelectedPriority(props.value);
  }, [props.value]);

  const handlePriorityChange = async (event) => {
    const priority = parseInt(event.target.value, 10);
    props.onValueChange(priority);
    setSelectedPriority(priority);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/estrategicoUpdate`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${props.user.token}`,
          },
          body: JSON.stringify({
            estrategico: priority,
            projectId: props.id,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      toast.success("Prioridade atualizada com sucesso.");
    } catch (error) {
      console.log(error);
      toast.error("Erro ao atualizar a prioridade do projeto.");
    }
  };

  return (
    <>
      {props.isAdmin ? (
        <div
          className="select-wrap"
          style={{ backgroundColor: selectedPriorityToColor(selectedPriority) }}
        >
          <div
            id={`selectColor-${props.id}`}
            className="color-selector"
            style={{
              backgroundColor: selectedPriorityToColor(selectedPriority),
              fontFamily: "Poppins, sans-serif",
              fontSize: "14px",
              justifySelf: "center",
            }}
          >
            <select onChange={handlePriorityChange} value={selectedPriority}>
              <option value="0">Selecione</option>
              {priorityValues.map((priority) => (
                <option key={priority.value} value={priority.value}>
                  {priority.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      ) : (
        <div
          className="p"
          style={{ backgroundColor: selectedPriorityToColor(selectedPriority) }}
        >
          {priorityValues.find((p) => p.value === selectedPriority)?.label}
        </div>
      )}
    </>
  );
};

// Helper function to map priority value to color
const selectedPriorityToColor = (priority) => {
  switch (priority) {
    case 1:
      return "#B0C4DE"; // Muito baixo
    case 2:
      return "#ADD8E6"; // Baixo
    case 3:
      return "#87CEEB"; // Médio
    case 4:
      return "#1E90FF"; // Alto
    case 5:
      return "#4169E1"; // Muito Alto
    default:
      return "";
  }
};

export default SelectEstrategico;
