import * as React from "react";
import { toast } from "react-toastify";
import BasicTable from "../../components/Home/BasicTable";
import Header from "../../components/Header/Header";
import UpdateDate from "../../components/UpdateDate";
import { adminId } from "../../constants";

export default function Home(props) {
  const [projects, setProjects] = React.useState([]);
  const [allProjects, setAllProjects] = React.useState([]);
  const [project, setProject] = React.useState("todos");
  const [projectName, setProjectName] = React.useState("");
  const [verticals, setVerticals] = React.useState([]);
  const [vertical, setVertical] = React.useState("todas");
  const [estado, setEstado] = React.useState("ativo");
  const [statusColor, setStatusColor] = React.useState("todos");
  const [deadline, setDeadline] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [reload, setReload] = React.useState(0);
  const [dateUpdate, setdateUpdate] = React.useState(0);
  const [owner, setOwner] = React.useState([]);

  const { onLogout } = props;

  const onChangeProject = (event) => {
    setProject(event.target.value);
  };
  const onChangeProjectName = (event) => {
    if (event.target.value.length >= 3) {
      setProjectName(event.target.value);
    }else if(event.target.value.length <= 2){
      setProjectName("");

    }
  };
  const onChangeVertical = (event) => {
    setVertical(event.target.value);
  };

  const onChangeEstado = (event) => {
    setEstado(event.target.value);
  };
  const onChangeDeadline = (event) => {
    setDeadline(event.target.value);
  };
  const onChangeStatusColor = (event) => {
    setStatusColor(event.target.value);
  };

  const getProjects = React.useCallback(async () => {
    try {
      const encodedStatusColor = encodeURIComponent(statusColor);
      const encodedProjectName = encodeURIComponent(projectName);
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/projects?id=${project === "todos" ? "" : project
        }&vertical=${vertical === "todas" ? "" : vertical}&estado=${estado}&statusColor=${encodedStatusColor}&projectName=${encodedProjectName}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.ok === false) {
        throw new Error(response.statusText);
      }
      const allProjects = await response.json();
      setProjects(allProjects);
    } catch (error) {
      if (error.message === "Forbidden") {
        return props.onLogout();
      }
      toast.error("Erro ao recarregar projetos.");
    } finally {
      setLoading(false);
    }
  }, [estado, project, vertical, statusColor, projectName]);

  const getAllProjects = async (event) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/projects/all`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.ok === false) {
        throw new Error(response.statusText);
      }
      const allProjects = await response.json();
      setAllProjects(allProjects);
    } catch (error) {
      toast.error("Erro ao recarregar projetos.");
    }
  };

  const getVerticals = async (event) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/verticals`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.ok === false) {
        throw new Error(response.statusText);
      }
      const allVerticals = await response.json();
      setVerticals(allVerticals);
    } catch (error) {
      toast.error("Erro ao recarregar projetos.");
    }
  };
  const getOwners = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/members`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (response.ok === false) {
        throw new Error(response.statusText);
      }
      const allOwner = await response.json();

      setOwner(allOwner[0]);
    } catch (error) {
      //("ERROR")
      toast.error("Erro ao recarregar projetos.");
    }
  };

  React.useEffect(() => {
    getAllProjects();
    getVerticals();
    getOwners();
  }, []);

  React.useEffect(() => {
    getProjects();
  }, [project, vertical, estado, statusColor, getProjects, reload]);

  return (
    <>
      <Header  onLogout={props.onLogout} user={props.user}/>

      <section className="filter-search">
        <div className="filter-search-wrap">
          <div className="search-box">
              <label for="search">O que você procura?</label>
              <div className="input-search-wrap">
                  <input name="search" type="text" placeholder="Procure pelo nome " onChange={onChangeProjectName}/>
                  <i className="search-icon" style={{backgroundImage: "images/search-ico.png"}}></i>
              </div>
          </div>
          <div className="select-box">
            <label for="statusColor">Status</label>
            <div className="select-wrap">
              <select name="statusColor" id="statusColor" onChange={onChangeStatusColor}>
                <option value="todos"> Todos </option>
                <option value="#7CFC00"> Em andamento </option>
                <option value="#FF0000"> Atrasado </option>
                <option value="#87CEEB"> Concluído </option>
                <option value="#FF9E00"> Pausado </option>
                <option value="#FFF000"> Falta informação </option>
              </select>
            </div>
          </div>
          <div className="select-box">
            <label for="departamento">Departamento</label>
            <div className="select-wrap">
              <select name="departamento" id="departamento" onChange={onChangeVertical}>
                <option value="todas">Todos</option>

                {verticals.map((value) => {
                  return (
                    <option key={value.KEYWORDS} value={value.KEYWORDS}>
                      {value.KEYWORDS}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="select-box">
            <label for="estato">Estado</label>
            <div className="select-wrap">
              <select name="estado" id="estado" onChange={onChangeEstado}>
                <option value="">Todos</option>
                <option value="ativo">Ativos</option>
                <option value="inativo">Inativos</option>
              </select>
            </div>
          </div>
        </div>
      </section>
      <UpdateDate
        isAdmin={!!props.user && props.user.id_user === adminId}
        user={props.user}
       />
      <BasicTable
        key="basicTable"
        projects={projects}
        isAdmin={!!props.user && props.user.id_user === adminId}
        user={props.user}
        owners={owner}
      />
    </>
  );
}
