import { Button } from '@mui/material';
import React from 'react'

export default function Header(props) {
    return(
        <>
            <header>
                <div className="entre-header">
                    <div className="entre-header-wrap">
                      <div style={{width: "94%", display: "flex", justifyContent: "space-between"}}>
                          <div className="logo-wrap" style={{margin: "0 auto"}}>
                            <a href="/">
                              <img src="/images/logo-pmovil-orange.png" alt="logo-Pmovil" title="Pmovil Logo" href="/" />
                              </a>
                              <span>PROJETOS</span>
                          </div>
                          <div style={{alignSelf: "center"}}>
                              {!!props.user && !!props.user.id_user && !props.isAdmin ?
                                      <Button
                                        type="submit"
                                        variant="outlined"
                                        onClick={() => props.onLogout()}
                                        sx={{
                                            width: 120,
                                            alignSelf: "center",
                                            backgroundImage: `linear-gradient(180deg, #0048FA 0%, #1C2439 100%)`,
                                            color: "#fff",
                                            fontWeight: "bold",
                                            borderRadius: 20,
                                            border: '1px solid #AFACAC',
                                            "&:hover": {
                                                color: "white",
                                            },
                                        }}
                                      >
                                          Sair
                                      </Button>
                                :
                                <Button
                                    type="submit"
                                    variant="outlined"
                                    onClick={(onLogout) => window.location.href = "/login"}
                                    sx={{
                                        width: 120,
                                        alignSelf: "center",
                                        backgroundImage: `linear-gradient(180deg, #0048FA 0%, #1C2439 100%)`,
                                        color: "#fff",
                                        fontWeight: "bold",
                                        borderRadius: 20,
                                        border: '1px solid #AFACAC',
                                        "&:hover": {
                                            color: "white",
                                        },
                                    }}
                                  >
                                      Admin Area
                                  </Button>
                                }
                          </div>
                      </div>
                    </div>
                </div>
            </header>
            <section className="breadcrumbs">
            <ul className="breadcrumbs-list">
            <li className={`breadcrumbs-item ${window.location.pathname === '/' ? 'active' : ''}`}>
                <a href="/">
                  <p style={{ cursor: "pointer" }}>Dashboard</p>
                </a>
              </li>
              <li className={`breadcrumbs-item ${window.location.pathname === '/prioridade/' ? 'active' : ''}`}>
                <a href="/prioridade/">
                {/* <a href="/emconstrucao/"> */}
                  <p style={{ cursor: "pointer" }}> Matriz de Priorização</p>
                </a>
              </li>
              <li className={`breadcrumbs-item ${window.location.pathname === '/reuniaostatus/' ? 'active' : ''}`}>
                 <a href="/reuniaostatus/"> 
                    <p style={{ cursor: "pointer" }}>Reuniões de Status & Comitê</p>
                </a>
              </li>
            </ul>
          </section>
        </>
    )
}