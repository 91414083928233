import ValuesInput from "../ValuesInput";
import { adminId } from "../../../constants.js";

const InputItems = ({ ulId, handleValueChange, costTableId , costs, isAdmin, user}) => {
  const inputItems = [];
  for (let i = 1; i < 25; i++) {
    const inputId = ulId + i;
    inputItems.push(
      <li key={`item-${inputId}`} className="table-item tab-col-3-5" inputId={`input-${inputId}`}>
        <ValuesInput  isAdmin={!!user && user.id_user === adminId}
                  user={user} rowId={ulId} id={inputId} index={i} handleValueChange={handleValueChange} costTableId={costTableId} costs={costs} />
      </li>
    );

    if (i % 2 === 0) {
      inputItems.push(
        <li key={`extra-${i}`} className="center header-item tab-col-0-36">
          <p></p>
        </li>
      );
    }
  }
  return inputItems;
  
};

export default InputItems;
