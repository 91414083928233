import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import "./CostsTable.css";
import React, { useState, useEffect } from "react";

export default function InputDescription({ ulId, onDescriptionChange, value, isAdmin }) {
  const [description, setDescription] = useState(value || '');
  const [isEditing, setIsEditing] = useState(false); // Controla o modo de edição

  const handleChangeDescription = (newValue) => {
    setDescription(newValue);
    onDescriptionChange(ulId, newValue); 
  };

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setDescription(newValue);
    handleChangeDescription(newValue);
  };

  const handleEditClick = () => {
    if (isAdmin) {
      setIsEditing(true);
    }
  };

  const handleBlur = () => {
    setIsEditing(false);
  };

  return (
    <Box
      component="form"
      sx={{
        width: "100%",
      }}
      noValidate
      autoComplete="off"
    >
      {isAdmin && isEditing ? ( 
        <TextField
          id={`input-${ulId}`}
          variant="outlined"
          value={description}
          onChange={handleInputChange}
          onBlur={handleBlur}
          autoFocus 
        />
      ) : (
        <div style={{paddingLeft:'5px'}} onClick={handleEditClick}>
          {description}
       
        </div>
      )}
    </Box>
  );
}
