import React from "react";
import DateInput from "../DateInput";
import { toast } from "react-toastify";
import { adminId } from "../../../constants.js";

const DateSelect = ({ onDateChange, projectId, value ,user, isAdmin}) => {
  const filteredValue = value.filter((v) => v !== undefined).slice(0, );
  while (filteredValue.length <= 11) {
    filteredValue.push({ TIME: "", ID_TIME: "" });
  }

  const dateSelectItems = filteredValue.map((item, index) => {

    return (
      <>
      
       <li className="center header-item tab-col-0-36">
      <p></p>
    </li>
      <li className="center header-item tab-col-7 background" key={index}>
       
          <DateInput
            id={index}
            onChangeDate={onDateChange}
            projectId={projectId}
            value={item.TIME}
            idTime={item.ID_TIME}
            isAdmin={!!user && user.id_user === adminId}
            user={user}
          />
       
      </li>
     
    </>
    );
  });

  return dateSelectItems;
};

export default DateSelect;
