import React, { useState } from "react";
import { adminId } from "../../constants";
import UploadFile from "../../components/UploadFile.js";
import ListUploadedFiles from "../../components/ListUploadedFiles.js";
import Header from "../../components/Header/Header";


export default function Reuniaostatus(props) {
  //(props)
  return (
    <>
        <Header   onLogout={props.onLogout} user={props.user}/>
        {!!props.user && props.user.id_user == adminId &&
            <UploadFile />
        }
        <ListUploadedFiles user={props.user}/>

    </>
  );
}
