import React, { useState } from "react";
import Header from "../../components/Header/Header.js";

import "./EmConstrucao.css";


export default function EmConstrucao(props) {
  return (
    <>
        <Header  onLogout={props.onLogout} user={props.user}/>
        <div className="construction-page">
          <div className="construction-content">
            <h1>Área em Construção</h1>
            <p>Estamos trabalhando nesta área para trazer ótimas novidades em breve. Volte mais tarde!</p>
          </div>
        </div>


    </>
  );
}
