import React, { useState, useEffect } from "react";
import "./SelectMembers.css";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import "./SelectMembers.css";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import CustomizedAccordions from "./Accordion.js";

import { Button } from "@mui/material";

const SelectMembers = (props) => {
  const { id } = useParams();
  const [projects, setProjects] = useState([]);
  const [projectName, setProjectName] = useState(""); // Novo estado para o nome do projeto

  const getProjects = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/projects`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (response.ok === false) {
        throw new Error(response.statusText);
      }

      const allProjects = await response.json();
      setProjects(allProjects);
      const project = allProjects.find((proj) => proj.ID === parseInt(id, 10));
      if (project) {
        setProjectName(project.NAME);
      }
    } catch (error) {
      //("ERROR")
      toast.error("Erro ao recarregar projetos.");
    }
  };

  const getPeople = async () => {
    try {
      props.setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/listMembers/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            authorization: `Bearer ${props.user.token}`,
          },
        }
      );
      if (response.ok === false) {
        throw new Error(response.statusText);
      }
      const allNames = await response.json();

      props.setPeople(allNames[0]);
    } catch (error) {
      toast.error("Erro ao recarregar projetos.");
    } finally {
      props.setLoading(false);
    }
  };

  useEffect(() => {
    getPeople();
    getProjects();
  }, []);

  return (
    <>
      {props.isAdmin ? (
        <div
          className="card"
          style={{
            shadowColor: "#dddddd",
            boxShadow: "0px 3px 30px -3px rgba(0,0,0,0.3)",
            width: "95%",
            marginInline: "50px",
            minHeight: "250px",
            paddingBottom: "10px",
            paddingTop: "10px",
            borderRadius: "10px",
            marginBottom: "40px",
            marginTop: "20px",
          }}
        >
          <div className="card-body">
            <div className="legendaselect">
              
              <div className="divCentral">
                <FormControl sx={{ m: 1, width: 400, marginLeft: '30px' }}>
                  <p
                    sx={{
                      margin: 0,
                      paddingTop: "40px",
                      marginTop: "40px",
                      paddingLeft: 0,
                      marginLeft: "20px",
                    }}
                  >
                    Selecione os membros do Projeto:
                  </p>

                  <Select
                    sx={{
                      margin: "20px",
                      borderRadius: "5px",
                      marginTop: "20px",
                    }}
                    multiple
                    value={props.selectedUserIds}
                    onChange={props.handleChangeSelectedPeople}
                  >
                    {props.people.map((person, index) => (
                      <MenuItem
                        key={person.USER_ID.toString()}
                        value={person.USER_ID.toString()}
                      >
                        {person.MEMBER}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              
              <div className="tituloResp">
                <div className="titulos">
                <div className="tituloResp" sx={{ textAlign: "center" }}>
                  MATRIZ DE RESPONSABILIDADES
                </div>
                <div className="tituloResp"> {projectName}</div>
                </div>
                <div sx={{textAlign:'center'}}>
                <Button
                  sx={{
                    marginTop: "80px",
                    // marginLeft: "40px",
                    color: "#19113",
                    borderColor: "#19113",
                    width: "250px",
                    textAlign:'center'
                  }}
                  variant="outlined"
                  disableElevation
                  className="salvarBtn"
                  size="medium"
                  onClick={props.handleSaveAndDelete}
                >
                  Salvar alterações
                </Button>
                </div>
              </div>
              <CustomizedAccordions />
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="tituloResp">
            <div className="tituloResp" sx={{ textAlign: "center" }}>
              MATRIZ DE RESPONSABILIDADES
            </div>
            <div className="tituloResp"> {projectName}</div>
          </div>
          <div
            className="card"
            style={{
              shadowColor: "#dddddd",
              // boxShadow: "0px 3px 30px -3px rgba(0,0,0,0.3)",
              width: "95%",
              marginInline: "50px",
              paddingBottom: "1px",
              paddingTop: "1px",
              borderRadius: "10px",
              // marginBottom: "40px",
              marginTop: "30px",
            }}
          >
            <div className="card-body">
              <div className="legendaNotAdmin">
                <p className="itemLegenda R">
                  <span style={{ fontWeight: "bold", fontSize: "18px" }}>
                    R:
                  </span>{" "}
                  Responsável por executar a tarefa
                </p>
                <p className="itemLegenda A">
                  <span style={{ fontWeight: "bold", fontSize: "18px" }}>
                    A:
                  </span>{" "}
                  Autoridade para aprovar a tarefa ou etapa
                </p>
                <p className="itemLegenda C">
                  <span style={{ fontWeight: "bold", fontSize: "18px" }}>
                    C:
                  </span>{" "}
                  Consultado para participar da tarefa
                </p>
                <p className="itemLegenda I">
                  <span style={{ fontWeight: "bold", fontSize: "18px" }}>
                    I:
                  </span>{" "}
                  Informado sobre o status da tarefa
                </p>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SelectMembers;
