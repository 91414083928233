const HeaderItems = () => {
  const headerItems = [];

  for (let i = 100, j = 200; headerItems.length < 23; i++, j++) {
    headerItems.push(
      <>
       
        <li
          className="center header-item tab-col-3-5"
          key={i}
          id={`column-${i}`}
        >
          <p>Hora</p>
        </li>
      </>
    );
    headerItems.push(
      <>
      <li className="center header-item tab-col-3-5" key={j} id={`column-${j}`}>
        <p>Valor</p>
      </li>
       <li className=" center header-item tab-col-0-36">
       <p> | </p>
     </li>
     </>
    );
  }

  return headerItems;
};

export default HeaderItems;
