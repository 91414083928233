import React, { useEffect, useState } from "react";
import PriorityTable from "../../components/Prioridade/PriorityTable.js";
import Header from "../../components/Header/Header";
import { adminId } from "../../constants";
import "./Prioridade.css";
import {
  Box,
  Select,
  MenuItem,
  FormControl,
  Backdrop,
  CircularProgress,
  Button,
} from "@mui/material";

function Prioridade(props) {
  const [loading, setLoading] = useState(false);

  const insertData = async () => {
    setLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/updateDataInsert`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${props.user.token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      // Retorna a resposta para verificar o estado (ok) posteriormente
      return response;
    } catch (error) {
      console.log(error);
      // Você pode escolher como deseja lidar com o erro aqui
      throw error;
    }finally {
      setLoading(false);
    }
  };

  const deleteData = async () => {
    setLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/updateDataDelete`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${props.user.token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      // Retorna a resposta para verificar o estado (ok) posteriormente
      return response;
    } catch (error) {
      console.log(error);
      // Você pode escolher como deseja lidar com o erro aqui
      throw error;
    } finally {
      setLoading(false);
    }
  };

  // ...

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [insertDataResponse, deleteDataResponse] = await Promise.all([
          insertData(),
          deleteData(),
        ]);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
     <Backdrop sx={{ color: "#fff", zIndex: 9999 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      <Header onLogout={props.onLogout} user={props.user} />
      <h2 className="title">Matriz de Priorização</h2>
      <div className="tablepriority">
        <PriorityTable
          isAdmin={!!props.user && props.user.id_user === adminId}
          user={props.user}
        />
      </div>
    </>
  );
}

export default Prioridade;
