const responsabilidades = [
  "R",
  "A",
  "C",
  "I",
];

const json_data = JSON.stringify({ responsabilidades });

export { responsabilidades, json_data };
