import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './StatusSelect.css';
import { toast } from "react-toastify";

const DeadlineSelect = (props) => {

  // console.log('props status: ', props)
  
  const [deadLine, setDeadline] = useState(props.value);
  
  const handleDeadlineChange = async (data) => {
    const deadline = data.target.value;

        setDeadline(deadline);


        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/deadline`,
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${props.user.token}`,
              },
              body: JSON.stringify({
                projectDeadLine: deadline,
                projectId: props.id,
              }),
            }
          );

          console.log(response)

          if (!response.ok) {
            throw new Error(response.statusText);
          }

          toast.success("Prazo atualizado com sucesso.");
        } catch (error) {
          toast.error("Erro ao atualizar o prazo do projeto.");
        }
  };

  useEffect(() => {
        setDeadline(props.value)
  }, []);


  return (
    <>
      {props.isAdmin ?
        <>
        <div className="select-wrap">
          <input type="text"
                value={deadLine}
                onChange={(event) => setDeadline(event.target.value)}
                onBlur={handleDeadlineChange}
                className="color-selector"
                style={{ fontFamily: "Poppins, sans-serif", fontSize: "12px", justifySelf: "center", border: "none", outline: "none" }}
            />
        </div>
      </>
      :
        <div className="select-wrap" >
         {props.value}
        </div>
      }
    
    </>
  );
};

export default DeadlineSelect;
