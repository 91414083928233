import * as React from "react";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import "./TableCard.css";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";

export default function TableCard(props) {
  const { id } = useParams();
  const [editingEmail, setEditingEmail] = useState(null);
  const [editedEmail, setEditedEmail] = useState("");
  const [selectedObjects, setSelectedObjects] = useState([]);
  const [creatingNewRow, setCreatingNewRow] = useState(false);
  const [convidados, setConvidados] = useState([]);
  const combinedData = [...selectedObjects, ...convidados];
  const [loading, setLoading] = useState([]);

  const [newRowData, setNewRowData] = useState({
    fullName: "",
    department: "",
    email: "",
  });

  const getConvidados = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/listConvidados/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (response.ok === false) {
        throw new Error(response.statusText);
      }
      const Convidados = await response.json();
      setConvidados(Convidados[0]);
    } catch (error) {
      toast.error("Erro ao recarregar projetos.");
    } finally {
    }
  };

  const handleAddNewRow = () => {
    setCreatingNewRow(true);
  };

  const generateRandomUserId = () => {
    const uniqueUserId = Math.floor(Math.random() * (10000 - 5000 + 1)) + 5000;
    setNewRowData((prevNewRowData) => ({
      ...prevNewRowData,
      USER_ID: uniqueUserId,
    }));
    return uniqueUserId;
  };

  const checkIfUserIdExists = async (userId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/checkUserId/${userId}`,
        {
          method: "GET",
          headers: {
            authorization: `Bearer ${props.user.token}`,
          },
        }
      );

      return response.status === 200; // Retorna true se o número de usuário existir no banco
    } catch (error) {
      console.error("Erro ao verificar número de usuário:", error);
      throw error;
    }
  };

  const handleSaveNewRow = async () => {
    setLoading(true);
    try {
      let uniqueUserId = generateRandomUserId();

      while (await checkIfUserIdExists(uniqueUserId)) {
        uniqueUserId = generateRandomUserId();
      }

      const response = await fetch(`${process.env.REACT_APP_API_URL}/newRow`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${props.user.token}`,
        },
        body: JSON.stringify({
          email: newRowData.email,
          fullName: newRowData.fullName,
          department: newRowData.department,
          projectId: id,
          userId: uniqueUserId,
        }),
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const result = await response.json();

      const newConvidado = {
        ...result.updatedData[0],
        USER_ID: uniqueUserId,
      };

      addNewConvidado(newConvidado);

      toast.success("Nova linha adicionada com sucesso.");
    } catch (error) {
      console.log(error);
      console.error(error);
      toast.error("Erro ao adicionar nova linha.");
    } finally {
      setCreatingNewRow(false);
      setNewRowData({
        fullName: "",
        department: "",
        email: "",
      });
      setLoading(false);
    }
  };

  const handleCancelNewRow = () => {
    setCreatingNewRow(false);
  };

  const handleCancelEdit = () => {
    setEditingEmail(null);
  };

  const handleEditEmail = (index) => {
    setEditingEmail(index);
    setEditedEmail(combinedData[index]?.EMAIL || "");
  };

  const handleEmailChange = (event) => {
    setEditedEmail(event.target.value);
  };

  const handleSaveEmail = async (index) => {
    const newNomes = [...combinedData];
    const updatedEmail = editedEmail;
    const userId = newNomes[index].USER_ID;

    newNomes[index].EMAIL = updatedEmail;

    setEditingEmail(null);
    setLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/updateEmail`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${props.user.token}`,
          },
          body: JSON.stringify({
            email: updatedEmail,
            userId: userId,
            projectId: id,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const json = await response.json();
      toast.success("Email atualizado com sucesso.");
    } catch (error) {
      console.log(error);
      toast.error("Erro ao atualizar Email.");
    } finally {
      setLoading(false);
    }
  };

  const DeleteMember = async (userId) => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/deleteMembers`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${props.user.token}`,
          },
          body: JSON.stringify({
            userId: userId,
            projectId: id,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(response.statusText);
      }
      handleDeleteMemberFromConvidados(userId);

      const isUserIdSelected = props.selectedMembers.some((name) =>
        props.nomes.some(
          (member) => member.USER_ID === userId && member.FULL_NAME === name
        )
      );

      setSelectedObjects((prevSelectedObjects) =>
        prevSelectedObjects.filter((obj) => obj.USER_ID !== userId)
      );

      if (isUserIdSelected) {
        props.setSelectedMembers((prevSelectedMembers) =>
          prevSelectedMembers.filter((name) => {
            const selectedNameObject = props.nomes.find(
              (member) => member.USER_ID === userId
            );
            return selectedNameObject.FULL_NAME !== name;
          })
        );
      }
      toast.success("Membro excluído com sucesso.");
    } catch (error) {
      console.log(error, "error");
      toast.error("Erro ao excluir membro.");
    } finally {
      setLoading(true);
    }
  };

  const handleDeleteMemberFromConvidados = (userId) => {
    setConvidados((prevConvidados) =>
      prevConvidados.filter((convidado) => convidado.USER_ID !== userId)
    );
  };

  const addNewConvidado = (convidado) => {
    setConvidados((prevConvidados) => [...prevConvidados, { ...convidado }]);
  };

  useEffect(() => {
    setSelectedObjects(props.selectedObjects);
  }, [props.selectedObjects]);

  useEffect(() => {
    getConvidados();
  }, []);

  return (
    <>
      <TableContainer
        sx={{
          width: "100%",
          maxWidth: "100%",
          minWidth: "100%",
          overflow: 'scroll',
          overflowX: "visible",
          overflowY: "auto",
          height: "50%",
          paddingBottom: 0,
          paddingTop: 0,
          marginTop: 0,
          marginBottom: 0,
        }}
      >
        <div className="table-wrap">
          <div className="table-projetos">
            <div className="tableHeader">
              <TableRow sx={{ width: "28vw", maxWidth: "28vw" }}>
                <li className="header-item tab-col-10">
                  {" "}
                  <p></p>
                </li>
                <li className="header-item tab-col-20 headers name">Nome</li>
                <li className="header-item tab-col-40 headers">Departamento</li>
                <li
                  sx={{ paddingLeft: "10px" }}
                  className="header-item tab-col-30 headers"
                >
                  E-mail
                </li>
              </TableRow>
            </div>
            <TableBody
            style={{marginTop: '50px'}}>
              <div className="tableBody" sx={{ width: "100%", overflowY: "auto", marginTop:'50px' }}>
                {combinedData.map((nome, index) => (
                  <TableRow
                    style={{
                      width: "22vw",
                      height: "70px",
                      minWidth: "32vw",
                      maxWidth: "32vw",
                    }}
                    className="table-line1 table-line"
                    key={nome.USER_ID}
                    id={nome.USER_ID}
                  >
                    {props.isAdmin ? (
                      <TableCell sx={{ width: "7%", minWidth: "7%" }}>
                        <IconButton
                          sx={{ marginTop: "5px", marginLeft: "5px", zIndex:2 }}
                          size="small"
                          title="Editar"
                          onClick={() => handleEditEmail(index)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          sx={{ color: "#c50d0f", paddingTop: 0 , zIndex:2}}
                          className="deleteButton"
                          title="Excluir"
                          aria-label="delete"
                          size="medium"
                          onClick={() => DeleteMember(nome.USER_ID)}
                        >
                          <DeleteIcon
                            style={{ cursor: "pointer" }}
                            fontSize="inherit"
                          />
                        </IconButton>
                      </TableCell>
                    ) : (
                      <TableCell sx={{ width: "7%", minWidth: "7%" }}>
                        <p> </p>
                      </TableCell>
                    )}

                    <TableCell
                      className="tableCell"
                      sx={{
                        width: "25%",
                        minWidth: "25%",
                      }}
                    >
                      <div className="tableRow">{nome.FULL_NAME}</div>
                    </TableCell>
                    <TableCell
                      className="tableCell"
                      sx={{
                        width: "20%",
                        minWidth: "20%",
                        paddingLeft: '35px'
                      }}
                    >
                      <div>{nome.DEPARTMENT?.replace("EntrePay -", " ").replace("Entre_", " ").replace("Pmovil", " ").replace("- EntrePay", " ").replace("Entre Pay -", " ")}</div>
                    </TableCell>
                    <TableCell
                      className="email tableCell"
                      sx={{
                        width: "50%",
                        minWidth: "45%",
                        maxWidth: "50%",
                      }}
                    >
                      {editingEmail === index ? (
                        <div className="editEmail">
                          <div>
                          <TextField
                            value={editedEmail}
                            onChange={handleEmailChange}
                          />
                          </div>
                          <div>
                            <Button onClick={() => handleSaveEmail(index)}>
                              Salvar
                            </Button>
                            <Button onClick={handleCancelEdit}>Cancelar</Button>
                          </div>
                        </div>
                      ) : (
                        nome.EMAIL
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </div>
              {creatingNewRow && (
                <TableRow>
                  <div className="creatingNewRow">
                    <div>
                      <TableCell>
                        <TextField
                          className="textfield tableCell"
                          sx={{
                            height: "30px",
                          }}
                          variant="standard"
                          value={newRowData.fullName}
                          onChange={(e) =>
                            setNewRowData({
                              ...newRowData,
                              fullName: e.target.value,
                            })
                          }
                          placeholder="Nome"
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          sx={{
                            height: "30px", paddingInline: '5px'
                          }}
                          className="textfield tableCell"
                          variant="standard"
                          value={newRowData.department}
                          onChange={(e) =>
                            setNewRowData({
                              ...newRowData,
                              department: e.target.value,
                            })
                          }
                          placeholder="Departamento"
                        />
                      </TableCell>
                      <TableCell
                      className="tableCell "
                        sx={{
                          height: "30px",
                        }}
                      >
                        
                        <TextField
                          className="textfield"
                          variant="standard"
                          value={newRowData.email}
                          onChange={(e) =>
                            setNewRowData({
                              ...newRowData,
                              email: e.target.value,
                            })
                          }
                          placeholder="E-mail"
                        />
                      </TableCell>
                    </div>
                    <div >
                      {/* <TableCell> */}
                        <Button
                          sx={{ textTransform: "capitalize" }}
                          onClick={handleSaveNewRow}
                        >
                          Salvar
                        </Button>
                        <Button
                          sx={{ textTransform: "capitalize" }}
                          onClick={handleCancelNewRow}
                        >
                          Cancelar
                        </Button>
                      {/* </TableCell> */}
                    </div>
                  </div>
                </TableRow>
              )}
              {props.isAdmin && (
                <div
                  className="addIcon"
                  sx={{ display: "flex", justifyContent: "end", zIndex: "10" }}
                >
                  <Fab
                    size="large"
                    color="primary"
                    aria-label="add"
                    title="Adicionar novo membro para o projeto"
                    onClick={handleAddNewRow}
                    sx={{
                      width: "30px",
                      height: "30px",
                      minHeight: 0,
                      boxShadow: "none",
                      zIndex: "10",
                    }}
                  >
                    <AddIcon />
                  </Fab>
                </div>
              )}
            </TableBody>
          </div>
        </div>
      </TableContainer>
    </>
  );
}
