import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import { adminId } from "../../constants";
import CostsTable from "../../components/Custos/CostsTable";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { Button, Grid, Typography } from "@mui/material";

function Custos(props) {
  const { id } = useParams();
  const [allProjects, setAllProjects] = useState([]);

  const getAllProjects = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/listProjects/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.ok === false) {
        throw new Error(response.statusText);
      }
      const AllProjects = await response.json();
      setAllProjects(AllProjects[0]);
    } catch (error) {
      console.log(error);
      toast.error("Erro ao recarregar projetos.");
    }
  };

  useEffect(() => {
    getAllProjects();
  }, []);

  return (
    <>
      <Header onLogout={props.onLogout} user={props.user} />
      <Typography
          sx={{
            fontFamily: ["'Poppins'", "sans-serif"].join(","),
            fontSize: 16,
            fontWeight: "700",
            color: "##000536",
            textAlign: "center",
            // textTransform: "uppercase",
            margin: "20px",
          }}
        >
         Matriz de Custos 
        </Typography>
      {allProjects.map((project) => (
        <Typography
        id={project.ID}
          key={project.ID}
          sx={{
            fontFamily: ["'Poppins'", "sans-serif"].join(","),
            fontSize: 16,
            fontWeight: "700",
            color: "##000536",
            textAlign: "center",
            textTransform: "uppercase",
            margin: "20px",
          }}
        >
         Projeto {project.NAME}
        </Typography>
        
      ))}
      <div className="tablepriority">
        <CostsTable
          isAdmin={!!props.user && props.user.id_user === adminId}
          user={props.user}
          allProjects={allProjects}
        />
      </div>
    </>
  );
}

export default Custos;
