import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home/Home';
import Login from './pages/Login/Login';
import React, { useState } from 'react';
import './App.css'
import Responsabilidade from './pages/Responsabilidade/Responsabilidade';
import Reuniaostatus from './pages/Reuniaostatus/Reuniaostatus';
import EmConstrucao from './pages/EmConstrucao/EmConstrucao';
import Comunicacao from './pages/Comunicacao/Comunicacao';
import Prioridade from './pages/Prioridade/Prioridade';
import { adminId } from "./constants";
import './css/main.css'
import './css/header.css'
import './css/breadcrumbs.css'
import './css/table.css'
import './css/filter-search.css'
import Header from './components/Header/Header';
import Custos from './pages/Custos/Custos';
import Microsoft from './pages/Microsoft';

function App() {
  const userData = localStorage.getItem("user");
  const [user, setUser] = React.useState(
    userData ? JSON.parse(userData) : null);

  const onLogin = (user) => {
    setUser(user);
  };

  const onLogout = () => {
    localStorage.removeItem("user");
    setUser(null);
  };

  return (
    <BrowserRouter>
      <Routes>
      <Route
          path="/microsoft/callback"
          element={<Microsoft onLogin={onLogin} />}
        />
        <Route
          path="/"
          element={user ?  <Home user={user} onLogout={onLogout} />  : <Login onLogin={onLogin} />}
        />
        <Route path="/comunicacao/:id"
        element={user ?  <Comunicacao user={user} onLogout={onLogout} /> : <Login onLogin={onLogin} />} />
        <Route path="/responsabilidade/:id"
        element={user ? <Responsabilidade user={user} onLogout={onLogout} /> : <Login onLogin={onLogin} />} />
        <Route path="/reuniaostatus/"
          element={
            user ? 
                (!!user.acessoDash && user.acessoDash != "" && 
                (user.acessoDash.toLowerCase() === "sim" || 
                user.acessoDash.toLowerCase() === "s" )) || user.id_user === adminId ?
                <Reuniaostatus user={user} onLogout={onLogout} /> : <Home user={user} onLogout={onLogout} />
            :
            <Login onLogin={onLogin} />
          } 
        />
        <Route path="/emconstrucao/"
        element={user ? <EmConstrucao user={user} onLogout={onLogout} /> : <Login onLogin={onLogin} />} />
        <Route path="/prioridade/"
        element={user ? <Prioridade user={user} onLogout={onLogout} /> : <Login onLogin={onLogin} />} />
         <Route path="/custos/:id"
        element={user ? <Custos user={user} onLogout={onLogout} /> : <Login onLogin={onLogin} />} />
        <Route path="/"
        element={user ? <Home user={user} onLogout={onLogout} /> : <Login onLogin={onLogin} />} />

<Route
 
  element={user ? <Header user={user} onLogout={onLogout} /> : <Login onLogin={onLogin} />}
/>

    
      </Routes>
    </BrowserRouter>
  );
}
export default App;