import React, { useState, useEffect } from 'react';
import './StatusSelect.css';
import { toast } from "react-toastify";
import "react-widgets/styles.css";
import { ChromePicker } from 'react-color';

const GeoColorChange = (props) => {

  const [selectedColor, setSelectedColor] = useState(false);
  const [showColorPicker, setShowColorPicker] = useState(false);

  const handleCloseColorPicker = async () => {
   
  };

  const handleColorChange = async (color) => {
    setSelectedColor(color.hex);
  };

  const handleIconClick = async () => {
    setShowColorPicker(!showColorPicker);
    if(showColorPicker){

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/geocolor`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${props.user.token}`,
            },
            body: JSON.stringify({
              projectGeoColor: selectedColor,
              projectId: props.id,
            }),
          }
        );
          console.log(response, "Resppp")
        if (!response.ok) {
          throw new Error(response.statusText);
        }

        toast.success("Cor projeto atualizada com sucesso.");

        window.location.reload();
      } catch (error) {
        console.log(error);
        toast.error("Erro ao atualizar a cor do projeto.");
      }
    }
  };



  
  return (
    <div>
      <span onClick={handleIconClick}>
            <img src="https://cdn-icons-png.flaticon.com/512/3162/3162558.png" style={{maxWidth: "30px"}}/>
      </span>
      {showColorPicker && (
        <div style={{ position: 'absolute', zIndex: '1' }}>
          <ChromePicker
            color={selectedColor}
            onChange={handleColorChange}
            onCloseColorPicker={handleCloseColorPicker}
          />
        </div>
      )}    
    </div>
    
    
  );
};

export default GeoColorChange;
