import * as React from "react";
import './StatusSelect.css';
import OwnerSelect from "./OwnerSelect.js";
import StatusSelect from "./StatusSelect";
import DeadlineSelect from "./DeadlineSelect";
import PriorityChange from "./PriorityChange";
import GeoColorChange from "./GeoColorChange";
import ManagerSelect from "./ManagerSelect.js";


const ChildCell = (props) => {

  const TextWithHtmlTags2 = ({ text }) => {
    if (!text) {
      return ""; 
    }

    const decodedText = text
    .replace(/\[P\]/g, '<p>')
    .replace(/\[\/P\]/g, '</p>')
    .replace(/&lt;/g, '<')
    .replace(/&lt;\/;/g, '</')
    .replace(/&gt;/g, '>')
    .replace(/&#39;/g, '\'')
    .replace(/&#34;/g, '"')

    
    // .replace(/&lt;\/b&gt;/g, "</b>")
    // .replace(/&lt;b&gt;/g, "<b>");
    return <div dangerouslySetInnerHTML={{ __html: decodedText }} />
  };

  const formataKeywords2 = (keywords) => {
    const wordsArray = keywords.split(',');
    // seleciona somente as palavras que não começam com "entre_"
    const entreWords = wordsArray.filter(word => !word.startsWith('entre_'));
    // remove "entre_" de cada palavra do array
    const words = entreWords.map(word => word.replace('entre_', '').toUpperCase());
    
    let formatedKeywords = words.join("<br />");
    return <span dangerouslySetInnerHTML={{ __html: formatedKeywords }} />
  };
  return (
    <div className="table-line child" style={{justifyContent: "flex-start", backgroundColor: !!props.project.GEO_COLOR ? `${props.project.GEO_COLOR}` : "#f1f0f0"}}>
        <ul>
            <li className="table-item tab-col-5" style={{backgroundColor: "#FFFFFF", paddingLeft: "40px"}}>
            </li>
            {
            props.isAdmin ?
            <>
                <li className="table-item tab-col-3">
                <GeoColorChange
                        id={props.project.ID}
                        key={props.project.ID}
                        value={props.project.GEO_COLOR}
                        user={props.user}
                    />
                </li>
                <li className="table-item tab-col-3">
                <PriorityChange
                        id={props.project.ID}
                        key={props.project.ID}
                        value={props.project.GENERAL_PRIORITY}
                        user={props.user}
                    />
                </li>

            </>
            :
            <li className="table-item tab-col-3" >
                <span className="tasks-grid-avatar ui-icon ui-icon-common-user-group" style={{width: "30px", minWidth: "30px"}}>
                <i style={{backgroundImage: `url('https://projetos.pmovil.com.br/upload/${props.project.IMG_PATH}/${props.project.IMG_NAME}')`}} />
                </span>
            </li>

            }
            <li className="table-item tab-col-15" >
                <a  href={`https://projetos.pmovil.com.br/workgroups/group/${props.project.ID}/tasks/`}  style={{textAlign: "left"}}>{props.project.NAME}</a>
            </li>
            <li className="table-item tab-col-15">
                <p className="descripton-text ">
                    <TextWithHtmlTags2 text={props.project.DESCRIPTION} />
                </p>
                {props.project.DESCRIPTION && 
                <span className="description-tooltips">
                    <TextWithHtmlTags2 text={props.project.DESCRIPTION} />
                </span>
                }
            </li>
            <li className="table-item tab-col-10">
                    <StatusSelect
                        id={props.project.ID}
                        key={props.project.ID}
                        value={props.project.STATUS_COLOR}
                        user={props.user}
                        isAdmin={props.isAdmin}
                    />
            </li>
            <li className="table-item tab-col-10">
                {!!props.project &&
                <DeadlineSelect
                        id={props.project.ID}
                        key={props.project.ID}
                        value={props.project.DEADLINE}
                        user={props.user}
                        isAdmin={props.isAdmin}
                    />
                }
            </li>
            <li className="table-item tab-col-30">
                <p className="descripton-text">
                    <TextWithHtmlTags2 text={props.project.LAST_FEED} />
                </p>
                {props.project.LAST_FEED && 
                <span className="description-tooltips">
                    <TextWithHtmlTags2 text={props.project.LAST_FEED} />
                </span>
                }
            </li>
            <li className="table-item tab-col-10">
                    <OwnerSelect
                        id={props.project.ID}
                        key={props.project.ID}
                        value={props.project.OWNER_ID}
                        user={props.user}
                        isAdmin={props.isAdmin}
                    />
            </li>
            <li className="table-item tab-col-10" style={{textAlign: "center"}}>
                    <ManagerSelect
                        id={props.project.ID}
                        key={`manager-${props.project.ID}`}
                        value={props.project.PROJECT_MANAGER_ID}
                        user={props.user}
                        isAdmin={props.isAdmin}
                    />
            </li>
            <li className="table-item preview tab-col-5">
                {/* <a className="table-previews" href={`/comunicacao/${value.ID}`} > */}
                <a className="table-previews" href="/emconstrucao/">

                    <img src="images/plano-comunicacao.png" alt="" />
                </a>
                {/* <a className="table-previews" href={`/responsabilidade/${value.ID}`}> */}
                <a className="table-previews" href="/emconstrucao/">

                    <img src="images/matrix-responsabilidade.png" alt="" />
                </a>
                <a className="table-previews" href="/emconstrucao/">
                    <img src="images/previsao-custos.png" alt="" />
                </a>
        </li>
        </ul>
    </div>
           
  );
}
export default ChildCell;
