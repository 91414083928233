import * as React from "react";
import { useState, useEffect } from "react";
import { useLocation, useHistory, useNavigate } from "react-router-dom";

export default function Microsoft(props) {
  const location = useLocation();
  const history = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get("code");
  const sessionState = searchParams.get("session_state");

  const getParams = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/social/auth/azureadoauth2/callback?code=${code}&session_state=${sessionState}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const user = await response.json();
        props.onLogin(user);
        localStorage.setItem("user", JSON.stringify(user));
        history("/");
      
      } else {
        console.log(`Código de status não esperado: ${response.status}`);
      }
    } catch (error) {
      console.log(error);
      
    }
  };

  useEffect(() => {
    getParams();
  }, []);

  return <div>Processando...</div>; 
}
