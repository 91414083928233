import React, { useState, useEffect } from 'react';
import './StatusSelect.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { toast } from "react-toastify";

const StatusSelect = (props) => {
  // //('props status: ', props)

  const [selectedColor, setSelectedColor] = useState('');
  
  const handleColorChange = async (event) => {
    //event.target.value me retorna o valor que está dentro de "color" (a cor selecionada)
    const color = event.target.value;
    setSelectedColor(color);

    //("color" ,color)
    //(event, 'EVENT')

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/status-color`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${props.user.token}`,
          },
          body: JSON.stringify({
            projectInputColor: color,
            projectId: props.id,
           /* projectInputStatus:  */
          }),
        }
      );

      //(response)

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      toast.success("Status atualizado com sucesso.");
    } catch (error) {
      toast.error("Erro ao atualizar o status do projeto.");
    }
  };

  useEffect(() => {
      setSelectedColor(props.value)
    
  }, []);
  
  return (
    <>
      {props.isAdmin ?
        <>
        <div className="select-wrap-statuselect" style={{backgroundColor: selectedColor}}>
          <div id={`selectColor-${props.id}`} className="color-selector-statuselect" style={{ backgroundColor:  !!selectedColor ? selectedColor : "#FFFFFF" }}>
            <select onChange={handleColorChange} value={!!selectedColor ? selectedColor : "#FFFFFF"} style={{color: !!selectedColor ? selectedColor : "#FFFFFF"}}>
              <option value="#FFFFFF">branco(nada)</option>
              <option value="#7CFC00" style={{ backgroundColor: '#7CFC00' }}>Em andamento(verde)</option>
              <option value="#FF0000" style={{ backgroundColor: '#FF0000' }}>Atrasado(vermelho)</option>
              <option value="#87CEEB" style={{ backgroundColor: '#87CEEB' }}>Concluído(Azul)</option>
              <option value="#FF9E00" style={{ backgroundColor: '#FF9E00' }}>Pausado(laranja)</option>
              <option value="#FFF000" style={{ backgroundColor: '#FFF000' }}>Falta informação(amarelo)</option>
            </select>
         
          </div>
        </div>
      </>
      :
        <div className="select-wrap-statuselect" style={{backgroundColor: props.value}}>
          <div id={`selectColor-${props.id}`} className="color-selector-statuselect" style={{ backgroundColor:  !!props.value ? props.value : "#FFFFFF", color: !!props.value ? props.value : "#FFFFFF" }}>
            {props.value === "#7CFC00" ? "Em andamento" :
              props.value === "#FF0000" ? "Atrasado" :
              props.value === "#87CEEB" ? "Concluído" :
              props.value === "#FF9E00" ? "Pausado" :
              props.value === "#FFF000" ? "Falta informação" :
              "---------"
            }
          </div>
        </div>
      }
    
    </>
  );
};

export default StatusSelect;
