import "./TableResp.css";
import React, { useState, useEffect, useReducer } from "react";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Box,
  Select,
  MenuItem,
  FormControl,
  Backdrop,
  CircularProgress,
  Button,
} from "@mui/material";
import { responsabilidades } from "./Json/RespJson.js";
import { TextField } from "@mui/material";
import DeleteIcon from "@material-ui/icons/Delete";
import SelectMembers from "./SelectMembers.js";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";

export default function TableResp(props) {
  const { id } = useParams();
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [responsibilities, setResponsibilities] = useState([]);
  const [newRow, setNewRow] = useState(null);
  const [resps, setResps] = useState([]);
  const [people, setPeople] = useState([]);

  const responsabilidadesColors = ["#2d71b0", "#b7d0e7", "#1d8cf7", "#adb9ca"];

  useEffect(() => {
    async function fetchData() {
      try {
        await Promise.all([getResponsibilities(), getResp()]);
        setIsPageLoaded(true);
      } catch (error) {
        toast.error("Erro ao recarregar a página.");
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    getResponsibilities();
  }, []);

  const getName = function (userId) {
    const person = people.find((p) => p.USER_ID === userId);
    return person ? person.MEMBER : "";
  };

  const getResp = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/listResp/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            authorization: `Bearer ${props.user.token}`,
          },
        }
      );
      if (response.ok === false) {
        throw new Error(response.statusText);
      }
      const AllResps = await response.json();
      const userIds = [];

      for (const element of AllResps) {
        for (const obj of element) {
          const userIdFromDb = obj.USER_ID;
          userIds.push(userIdFromDb);
        }
      }

      setResps(AllResps[0]);
      setInitialUsers(AllResps[0]);
    } catch (error) {
      toast.error("Erro ao recarregar Responsabilidades.");
    } finally {
      setLoading(false);
    }
  };

  const getResponsibilities = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/responsibilities/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            authorization: `Bearer ${props.user.token}`,
          },
        }
      );
      if (response.ok === false) {
        throw new Error(response.statusText);
      }
      const Responsabs = await response.json();

      // Assign RESPONSIBILITY_ID as the id for each row
      const updatedResponsibilities = Responsabs[0].map((row) => ({
        ...row,
        id: row.RESPONSIBILITY_ID,
      }));

      setResponsibilities(updatedResponsibilities);
    } catch (error) {
      toast.error("Erro ao recarregar projetos.");
    } finally {
      setLoading(false);
    }
  };

  const setInitialUsers = (aResps) => {
    const p = [];
    for (const r of aResps) {
      p.push(r.USER_ID.toString());
    }
    // remove os duplicados
    const unique = [...new Set(p)];
    setSelectedUserIds(unique);
  };

  const handleAddRow = async () => {
    try {
      setLoading(true);

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/updateRows`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${props.user.token}`,
          },
          body: JSON.stringify({
            projectId: id,
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        const newRowId = data?.result?.insertId;

        if (newRowId) {
          const newRow = {
            id: newRowId,
            projeto: "",
          };

          // Obtenha os valores dos inputs para as linhas existentes
          const existingRows = rows.map((row) => ({
            ...row,
            projeto: row.projeto,
          }));

          setRows((prevRows) => [...existingRows, newRow]);

          setResponsibilities((prevResponsibilities) => [
            ...prevResponsibilities,
            {
              RESPONSIBILITY_ID: newRowId,
              RESPONSIBILITY: "",
            },
          ]);

          setNewRow(newRow); // Atribua a nova linha à variável newRow
        } else {
          throw new Error("Objeto newRow não encontrado na resposta.");
        }
      } else {
        throw new Error("Erro ao adicionar linha.");
      }
    } catch (error) {
      console.error(error.message);
      toast.error("Erro na requisição.");
    } finally {
      setLoading(false);
    }
  };

  let newResp = [...resps];

  const handleChangeResp = (event, userName, projectId, userId) => {
    const { value } = event.target;
    let found = false;

    const updatedResps = newResp.map((obj) => {
      if (
        obj.USER_ID === parseInt(userId) &&
        obj.RESPONSIBILITY_ID === parseInt(projectId)
      ) {
        found = true;
        return {
          ...obj,
          RESP: value,
        };
      }
      return obj;
    });

    if (!found && value !== undefined) {
      const newObj = {
        USER: userName,
        USER_ID: parseInt(userId),
        RESPONSIBILITY_ID: parseInt(projectId),
        RESP: value,
      };
      updatedResps.push(newObj);
    }

    newResp = updatedResps;
    setResps(updatedResps);

    const updatedRows = rows.map((row) => {
      if (row.id === parseInt(projectId)) {
        return {
          ...row,
          [userId]: value !== undefined ? value : "", // Defina um valor vazio ('') se o valor for undefined
        };
      }
      return row;
    });

    setRows(updatedRows);
  };

  const getUserResp = function (aProjectId, aUserId) {
    for (const r of newResp) {
      if (r.USER_ID === aUserId && r.RESPONSIBILITY_ID === aProjectId) {
        return r.RESP;
      }
    }
  };

  const handleChangeSelectedPeople = (event) => {
    const {
      target: { value },
    } = event;

    setSelectedUserIds(value);

    const deselectedUserIds = selectedUserIds.filter(
      (id) => !value.includes(id)
    );

    // Update RESP to null for deselected user IDs
    const updatedNewResp = newResp.map((obj) => {
      if (deselectedUserIds.includes(obj.USER_ID.toString())) {
        return { ...obj, RESP: null };
      }
      return obj;
    });

    newResp = updatedNewResp;
    setResps(updatedNewResp);
    //(newResp, 'newResp');
  };

  const deleteResp = async () => {
    try {
      setLoading(true);

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/deleteResp`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${props.user.token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(response.statusText);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const onSaveResp = async () => {
    try {
      setLoading(true);

      const updates = [];

      for (const item of newResp) {
        const responsibilityId = item.RESPONSIBILITY_ID;
        const userId = item.USER_ID;
        const resp = item.RESP;

        const update = {
          responsibilityId: responsibilityId,
          memberId: userId,
          resp: resp,
          projectId: id,
        };

        updates.push(update);
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/updateResp`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${props.user.token}`,
          },
          body: JSON.stringify(updates),
        }
      );

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      toast.success("Responsabilidades salvas com sucesso.");
    } catch (error) {
      toast.error("Erro ao salvar responsabilidades.");
    } finally {
      setLoading(false);
    }
  };

  const onSaveInput = async (responsibilityId) => {
    try {
      const row = responsibilities.find(
        (r) => r.RESPONSIBILITY_ID === responsibilityId
      );
      const value = row.RESPONSIBILITY;

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/updateInput`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${props.user.token}`,
          },
          body: JSON.stringify({
            responsibilityInput: value,
            projectId: id,
            responsibilityId: responsibilityId,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(response.statusText);
      }
    } catch (error) {
      console.log(error.message);
      //toast.error("Erro ao atualizar texto.");
    }
  };

  const deleteRow = async (id) => {
    try {
      setLoading(true);

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/deleteRow`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${props.user.token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(response.statusText);
      }
    } catch (error) {
    } finally {
      setResponsibilities((prevResponsibilities) =>
        prevResponsibilities.filter((item) => item.RESPONSIBILITY_ID !== id)
      );

      setLoading(false);

      //window.location.reload();
    }
  };

  const updateRowToDelete = async (responsibilityId) => {
    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/updateToDelete`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${props.user.token}`,
          },
          body: JSON.stringify({
            projectId: null,
            responsibilityId: responsibilityId,
            urlId: id,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      deleteRow(responsibilityId);
      toast.success("Linha excluída.");
    } catch (error) {
      console.log(error.message);
    }
    setLoading(false);

    //await deleteRow(responsibilityId);
  };

  const handleSaveAndDelete = async () => {
    //setLoading(true);

    try {
      // (PUT)
      await onSaveResp();

      // (DELETE)
      await deleteResp();

      // Call onSaveInput for each responsibility
      for (const responsibility of responsibilities) {
        await onSaveInput(responsibility.RESPONSIBILITY_ID);
      }

      //toast.success("Atualização e exclusão concluídas com sucesso");
    } catch (error) {
      //console.error("Ocorreu um erro durante a atualização e exclusão:", error);
    }

    // setLoading(false);
  };

  useEffect(() => {
    getResp();
  }, [people]);

  const handleChangeInput = (event, rowId) => {
    const { value } = event.target;
    setResponsibilities((prevResponsibilities) =>
      prevResponsibilities.map((row) =>
        row.RESPONSIBILITY_ID === rowId
          ? { ...row, projeto: value, RESPONSIBILITY: value }
          : row
      )
    );
  };

  const headerStyle = {
    color: "white",
    backgroundColor: "#2d71b0",
    fontWeight: "bold",
    
  };

  useEffect(() => {
    const c = [];
    

    c.push({
      field: "projeto",
      headerName: "Responsabilidades",
      flex: 1,
      headerClassName: "MuiDataGrid-columnHeaderTitleContainer",
      headerAlign: "center", 
      sortable: false, 
      filterable: false,
      title: "Responsabilidades",

      renderHeader: (params) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ marginRight: "10px", fontWeight: "bold" }}>
            Responsabilidades
          </span>
          {props.isAdmin && (
            <Fab
              size="small"
              color="primary"
              aria-label="add"
              title="Adicionar linha"
              onClick={handleAddRow}
              sx={{ width: "30px", height: "30px", minHeight: 0 }}
            >
              <AddIcon />
            </Fab>
          )}
        </div>
      ),
      renderCell: (params) => {
        const projetoCell = props.isAdmin ? (
          <>
            <Fab
              title="Apagar linha"
              aria-label="delete"
              size="small"
              sx={{
                boxShadow: "none",
                backgroundColor: "#f1f0f0",
                color: "#c50d0f",
              }}
            >
              <DeleteIcon
                id={responsibilities.RESPONSIBILITY_ID}
                title="Deletar linha"
                onClick={() => updateRowToDelete(params.row.RESPONSIBILITY_ID)}
              />
            </Fab>
            <FormControl
              className="RespTitle"
              fullWidth
              sx={{
                marginLeft: "1px",
                textoverflow: "ellipsis",
                overflow: 'auto'

              }}
            >
              <TextField
                textoverflow="ellipsis"
                placeholder="Responsabilidade..."
                className="RespTitle"
                sx={{
                  marginLeft: "1px",
                  textoverflow: "ellipsis",
                  overflow: 'auto'

                 
                }}
                value={params.row.RESPONSIBILITY || ""}
                title={params.row.RESPONSIBILITY || ""}
                onChange={(event) =>
                  handleChangeInput(event, params.row.RESPONSIBILITY_ID)
                }
                id={params.row.RESPONSIBILITY_ID.toString()}
                onKeyDown={(event) => {
                  if (event.keyCode === 32) {
                    event.stopPropagation();
                  }
                }}
              />
            </FormControl>
          </>
        ) : (
          <p
            title={params.row.RESPONSIBILITY}
            className="RespTitle"
            style={{
              color: "#28207b",
              marginLeft: "1px",
              textoverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {params.row.RESPONSIBILITY}
          </p>
        );

        return projetoCell;
      },
    });

    for (const userId of selectedUserIds.map((id) => parseInt(id))) {
      c.push({
        field: String(userId),
        headerName: getName(userId),
        flex: 1,
        headerClassName: "MuiDataGrid-columnHeaderTitle css-mh3zap",
        rowClassName: "css-1x5jdmq",
        title: getName(userId),
        
        renderCell: (params) =>
          props.isAdmin ? (
            <Select
              value={getUserResp(params.id, userId) || ""} // Defina um valor padrão se getUserResp retornar undefined
              onChange={(event) =>
                handleChangeResp(event, getName(userId), params.id, userId)
              }
              sx={{
                justifyContent: "center",
                width: "100%",
                backgroundColor:
                  responsabilidadesColors[
                    responsabilidades.indexOf(getUserResp(params.id, userId))
                  ] || "transparent",
                fontWeight: "bold",
                textAlign: "center",
                textShadow: "0 0 0.2em white, 0 0 0.2em white",
                fontSize: "18px",
              }}
            >
              <MenuItem value={""} style={{ backgroundColor: "#f1f0f0" }}>
                Selecione
              </MenuItem>
              {responsabilidades.map((r, index) => (
                <MenuItem
                  key={index}
                  value={r}
                  style={{
                    backgroundColor: responsabilidadesColors[index],
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: "18px",
                  }}
                >
                  {r}
                </MenuItem>
              ))}
            </Select>
          ) : (
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "100%",
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: "bold",
                backgroundColor:
                  responsabilidadesColors[
                    responsabilidades.indexOf(getUserResp(params.id, userId))
                  ] || "transparent",
                fontSize: "18px",
                textShadow: "0 0 0.2em white, 0 0 0.2em white",
              }}
            >
              <p
                sx={{
                  color: "white",
                  fontSize: "18px",
                  textShadow: "0 0 0.2em white, 0 0 0.2em white",
                }}
              >
                {getUserResp(params.id, userId)}
              </p>
            </div>
          ),
      });
    }

    setColumns(c);
  }, [selectedUserIds]);

  if (!isPageLoaded) {
    // Render a loading state or return null while the page is not fully loaded
    return (
      <Backdrop sx={{ color: "#fff", zIndex: 9999 }} open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
  return (
    <>
      <style>
        {`
          .custom-row {
            background-color: #f1f0f0;
          }
          .even-row {
            background-color: #f1f0f0;
          }
          .odd-row {
            background-color: #f1f0f0;
          }
        `}
      </style>
      <SelectMembers
        isAdmin={props.isAdmin}
        user={props.user}
        handleChangeSelectedPeople={handleChangeSelectedPeople}
        selectedUserIds={selectedUserIds}
        handleSaveAndDelete={handleSaveAndDelete}
        setPeople={setPeople}
        people={people}
        setLoading={setLoading}
      />
      <Box
        sx={{
          witdh: "300px",
        }}
      >
        <Backdrop sx={{ color: "#fff", zIndex: 9999 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <DataGrid
          getRowId={(row) => row.RESPONSIBILITY_ID}
          rows={responsibilities}
          columns={columns}
          headerClassName={"custom-header"}
          hideFooterPagination
          hideFooterSelectedRowCount
          disableColumnMenu
          getRowClassName={(params) =>
            `custom-row ${params.index % 2 === 0 ? "even-row" : "odd-row"}`
          }
        />
        <style>
          {`
            .custom-header {
              display: flex;
              align-items: center;
              font-weight: bold;
              background-color: #2d71b0;
              color: white;
            }
        
            .custom-header MuiDataGrid-colCellTitle {
              flex: 1;
            }

            .MuiDataGrid-columnHeaderTitleContainer{
              
            }
          `}
        </style>
      </Box>
    </>
  );
}
