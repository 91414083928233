import * as React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import InputDescription from "./InputDescription";
import DateSelect from "./hooks/DateSelect";
import "./CostsTable.css";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import HeaderItems from "./hooks/HeaderItems";
import InputItems from "./hooks/InputItems";
import InputTotal from "./hooks/Total";
import { toast } from "react-toastify";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { adminId } from "../../constants.js";

export default function CostsTable(props) {
  const { id } = useParams();
  const [tableRows, setTableRows] = useState([]);
  const [inputValues, setInputValues] = useState([]);
  const [inputDate, setInputDate] = useState([]);
  const [costs, setCosts] = useState([]);
  const [par, setPar] = useState(0);
  const [impar, setImpar] = useState(0);
  const getCosts = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/listValues/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (response.ok === false) {
        throw new Error(response.statusText);
      }
      const Costs = await response.json();
      setCosts(Costs[0]);
    } catch (error) {
      toast.error("Erro ao recarregar projetos.");
    } finally {
      console.log(costs, "costs");
    }
  };

  const getDate = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/getDate/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (response.ok === false) {
        throw new Error(response.statusText);
      }
      const values = await response.json();

      const transformDate = (dateString) => {
        const dataDoBanco = new Date(dateString);
        const mes = dataDoBanco.getMonth() + 1;
        const ano = dataDoBanco.getFullYear();
        return `${mes.toString().padStart(2, "0")}/${ano.toString()}`;
      };

      const valuesTransformados = values[0].map((obj) => ({
        ...obj,
        TIME: transformDate(obj.TIME),
      }));
      setInputDate(valuesTransformados);
    } catch (error) {
      toast.error("Erro ao recarregar projetos.");
    } finally {
    }
  };

  const handleDescriptionChange = async (ulId, newDescription) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/updateDescription`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${props.user.token}`,
          },
          body: JSON.stringify({
            projectId: id,
            description: newDescription,
            rowId: ulId,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(response.statusText);
      }
      toast.success("Descrição do custo alterada");
    } catch (error) {
      console.log(error);
      toast.error("Erro ao alterar a descrição do custo.");
    }
  };

  const handleDateChange = async (dateId, newValue) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/updateDate`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${props.user.token}`,
          },
          body: JSON.stringify({
            projectId: id,
            time: newValue,
            timeId: dateId,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      toast.success("Descrição do custo alterada");
    } catch (error) {
      console.log(error);
      toast.error("Erro ao alterar a descrição do custo.");
    }
  };

  const generateRandomRowId = () => {
    const randomRowId = Math.floor(Math.random() * (10000 - 5000 + 1)) + 5000;
    return randomRowId;
  };

  const checkIfRowIdExists = async (rowId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/checkRowId/${rowId}`,
        {
          method: "GET",
          headers: {
            authorization: `Bearer ${props.user.token}`,
          },
        }
      );

      return response.status === 200;
    } catch (error) {
      console.error("Erro ao verificar número de usuário:", error);
      throw error;
    }
  };

  const handleAddNewUl = async () => {
    let randomRowId = generateRandomRowId();
    while (await checkIfRowIdExists(randomRowId)) {
      randomRowId = generateRandomRowId();
    }
    const newTableRow = { ID: randomRowId, description: "" };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/newRowCosts`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${props.user.token}`,
          },
          body: JSON.stringify({
            rowId: randomRowId,
            projectId: id,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      toast.success("Linha adicionada.");
    } catch (error) {
      console.log(error);
      toast.error("Erro ao adicionar linha.");
    } finally {
      setTableRows([...tableRows, newTableRow]);
    }
  };

  const getRows = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/listRows/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (response.ok === false) {
        throw new Error(response.statusText);
      }
      const rows = await response.json();
      setTableRows(rows[0]);
    } catch (error) {
      toast.error("Erro ao recarregar projetos.");
    } finally {
    }
  };

  const DeleteCost = async (rowId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/deleteCosts`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${props.user.token}`,
          },
          body: JSON.stringify({
            rowId: rowId,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      setTableRows((prevTableRows) =>
        prevTableRows.filter((row) => row.ID !== rowId)
      );

      toast.success("Custo excluído com sucesso.");
    } catch (error) {
      console.log(error, "error");
      toast.error("Erro ao excluir custo.");
    }
  };

  useEffect(() => {
    getRows();
    getDate();
    getCosts();
  }, []);

  const handleValueChange = async (inputId, newValue, index, rowId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/updateValues`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${props.user.token}`,
          },
          body: JSON.stringify({
            projectId: id,
            valueId: inputId,
            value: Number(newValue) ? Number(newValue) : 0,
            rowId: rowId,
            index: index,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(response.statusText);
      }
      const existingIndex = costs.findIndex(
        (cost) => cost.ID_VALUE === inputId
      );
      if (existingIndex !== -1) {
        setCosts((prevCosts) => {
          const updatedCosts = [...prevCosts];
          updatedCosts[existingIndex] = {
            ...updatedCosts[existingIndex],
            VALUE: Number(newValue),
          };
          return updatedCosts;
        });
      } else {
        setCosts((prevCosts) => [
          ...prevCosts,
          {
            PROJECT_ID: id,
            ID_VALUE: inputId,
            VALUE: Number(newValue),
            ROW_ID: rowId,
            INDEX_TABLE: index,
          },
        ]);
      }

      toast.success("Custo alterado");
    } catch (error) {
      console.log(error);
      toast.error("Erro ao alterar custo.");
    }
  };

  return (
    <>
      <div style={{ textAlign: "left", marginLeft: "50px" }}>
        <p>
          Total Mês: <span style={{ fontWeight: "bold" }}>R${impar},00</span>
        </p>
        <p>
          Total Geral: <span style={{ fontWeight: "bold" }}>R${par},00</span>{" "}
        </p>
      </div>
      <div className="tabelaPriority table-wrap">
        <div className="table-projetos">
          <div className="table-header flex padding">
            <ul>
              <li className="header-item tab-col-10  header-item-wrap">
                <p></p>
              </li>
              <li className=" center header-item tab-col-0-36"></li>
              <li className=" center header-item tab-col-0-36"></li>
              <DateSelect
                onDateChange={handleDateChange}
                projectId={id}
                value={inputDate}
                isAdmin={!!props.user && props.user.id_user === adminId}
                user={props.user}
              />
            </ul>
          </div>

          <div className="table-header">
            <ul>
              <li className=" center header-item tab-col-0-36"></li>
              <li className=" center header-item tab-col-0-36"></li>
              <li className="header-item tab-col-10  header-item-wrap">
                <p>Descrição do custo</p>
              </li>
              <li className="center header-item tab-col-0-36">
                <p></p>
              </li>

              {HeaderItems()}
            </ul>
          </div>
          <div className="table-line">
            {tableRows.map((row) => (
              <ul key={row.ID} id={row.ID}>
                <li className=" center header-item tab-col-0-36"></li>
                {props.isAdmin? ( // Verifique se o usuário é admin
                    <li className=" center header-item tab-col-0-36">
                      <IconButton
                        sx={{ color: "#c50d0f", paddingTop: 0, zIndex: 2 }}
                        className="deleteButton button"
                        title="Excluir"
                        aria-label="delete"
                        size="medium"
                        onClick={() => DeleteCost(row.ID)}
                      >
                        <DeleteIcon
                          className="button"
                          style={{ cursor: "pointer" }}
                          fontSize="inherit"
                        />
                      </IconButton>
                    </li>
                  ):
                  <li className=" center header-item tab-col-0-36"></li>}
                <li
                  className="table-item tab-col-10"
                  style={{ wordWrap: "break-word" }}
                  key={row.ID}
                >
                  <InputDescription
                    ulId={row.ID}
                    onDescriptionChange={handleDescriptionChange}
                    value={row.DESCRIPTION}
                    isAdmin={!!props.user && props.user.id_user === adminId}
                    user={props.user}
                  />
                </li>
                <li className="center header-item tab-col-0-36">
                  <p></p>
                </li>
                <InputItems
                  ulId={row.ID}
                  handleValueChange={handleValueChange}
                  costs={costs}
                  isAdmin={!!props.user && props.user.id_user === adminId}
                  user={props.user}
                />
              </ul>
            ))}
            <ul className="total">
              <li className=" center header-item tab-col-0-36"></li>
              <li className=" center header-item tab-col-0-36"></li>
              <li className="table-item tab-col-10 center">
                <p className="center">TOTAL</p>
              </li>
              <li className="center header-item tab-col-0-36">
                <p></p>
              </li>
              <InputTotal
                ulId={1000}
                costs={costs}
                setPar={setPar}
                setImpar={setImpar}
              />

              <li className=" center header-item tab-col-0-36">
                <p></p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {props.isAdmin? 
      <div
        className="addIcon"
        sx={{ display: "flex", justifyContent: "end", zIndex: "10" }}
      >
        <Fab
          size="large"
          color="primary"
          aria-label="add"
          title="Adicionar custo"
          onClick={handleAddNewUl}
          sx={{
            width: "30px",
            height: "30px",
            minHeight: 0,
            boxShadow: "none",
            zIndex: "10",
          }}
        >
          <AddIcon />
        </Fab>
        <p style={{ marginLeft: "5px", fontSize: "12px" }}>
          Clique para adicionar um novo custo.
        </p>
      </div>
      :(<p></p>)}
    </>
  );
}
