const Total = ({ ulId, costs, setPar, setImpar }) => {
  const InputTotal = [];

  let newTotal = [];
  console.log('---------------------------','costs')

  for (let c of costs) {
    console.log(c ,'costs')
    console.log(costs ,'costs array')

    let isOld = false;
    for (let item of newTotal) {
      if (item.index === c.INDEX_TABLE) {
        item.total += c.VALUE;
       
        console.log(item.total, 'totall', c.VALUE, 'valorr')
        break;
      }
    }
    if (!isOld) {
      newTotal.push({ index: c.INDEX_TABLE, total: c.VALUE });
    }
  }
  
  let par = 0;
  let impar = 0;

  for (let item of newTotal) {
    // console.log(item.index, 'itemindex', item.total, 'itemtotal')
    if (item.index % 2 === 0) {
      par += item.total;
      // console.log(item.total, 'item total')
      // console.log(par, 'par')
      

    }else{
      impar += item.total;
      // console.log(item.total, 'item total')
      // console.log(impar, 'impar')
      
    }
  }

  setPar(par);
  setImpar(impar);
  
  console.log('impar:' ,impar)
  console.log('par:' ,par)


  for (let i = 1; i < 25; i++) {
    const inputId = ulId + i;
    const totalItem = newTotal.find((item) => item.index === i);

    InputTotal.push(
      <li
        key={`item-${inputId}`}
        className="table-item tab-col-3-5 center"
        id={`input-${inputId}`}
      >
        <p>R${totalItem ? totalItem.total : 0},00</p>
      </li>
    );

    if (i % 2 === 0) {
      InputTotal.push(
        <li className="center header-item tab-col-0-36">
          <p></p>
        </li>
      );
    }
  }

  return InputTotal;
};

export default Total;
