import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import * as React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
import './CostsTable.css'

dayjs.locale("pt-br");

export default function DateInput({ id, onChangeDate, value, isAdmin, user }) {
  const dateValue = dayjs(value, "MM/YYYY");
  const [date, setDate] = React.useState(dateValue);

  React.useEffect(() => {
    const newDateValue = dayjs(value, "MM/YYYY");
    setDate(newDateValue);
  }, [value]);

  const handleChangeDate = (newValue) => {
    setDate(newValue);
    onChangeDate(id, newValue);
  };

  // Verifique se a data é válida (não é NaN) e se o ano não é igual a "Invalid Date"
  const isDateValid = date.isValid() && !date.format("YYYY").includes("Invalid Date");

  return (
    <React.Fragment>
      {isAdmin ? (
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          locale="pt-br"
          onChange={handleChangeDate}
          id={id}
        >
          <DatePicker
            id={id}
            views={["month", "year"]}
            format={"MM/YYYY"}
            value={date}
            onChange={handleChangeDate}
          />
        </LocalizationProvider>
      ) : (
        <TextField
          style={{ background: 'white', fontWeight:'bold' }}
          value={isDateValid ? date.format("MM/YYYY") : "MM/AAAA"}
          inputProps={{ readOnly: true }}
        />
      )}
    </React.Fragment>
  );
}
