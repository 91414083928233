import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import './PriorityTable.css'

const ConcorremEsforco = (props) => {
  const [selectedPriority, setSelectedPriority] = useState(props.value);

  useEffect(() => {
    setSelectedPriority(props.value);
  }, [props.value]);

  const handlePriorityChange = async (event) => {
    const priority = event.target.value;
    // props.onValueChange(priority);
    setSelectedPriority(priority);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/concorremEsforco`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${props.user.token}`,
          },
          body: JSON.stringify({
            concorremEsforco: priority,
            projectId: props.id,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      toast.success("Prioridade atualizada com sucesso.");
    } catch (error) {
      console.log(error);
      toast.error("Erro ao atualizar a prioridade do projeto.");
    }
  };

  // Define the colors associated with each priority value
  const priorityColors = {
    "Desenvolvimento": "#adb9ca",
    "TI Sustentaçao": "#bdd7ee",
    "DXC": "#b8a7fe",
    "Não concorrem": "#afabab",
  };
  return (
    <>
      {props.isAdmin ? (
        <div
          className="select-wrap"
          style={{ backgroundColor: priorityColors[selectedPriority] || "#fff" }}
        >
          <div
            id={`selectColor-${props.id}`}
            className="color-selector"
            style={{
              backgroundColor: priorityColors[selectedPriority] || "#fff",
              fontFamily: "Poppins, sans-serif",
              fontSize: "14px",
              justifySelf: "center",
            }}
          >
            <select onChange={handlePriorityChange} value={selectedPriority}>
              <option value="0">Selecione</option>
              {Object.keys(priorityColors).map((priority) => (
                <option key={priority} value={priority}>
                  {priority}
                </option>
              ))}
            </select>
          </div>
        </div>
      ) : (
        <div
          className="p"
          style={{ backgroundColor: priorityColors[selectedPriority] || "#fff" }}
        >
          {selectedPriority} 
        </div>
      )}
    </>
  );
};

export default ConcorremEsforco;
